<template>
    <div class="modal-content bg-grey-lightest leave-comment-wrap pb-5">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title" id="titleAmbassadorForm">
                {{ $t('editShort.form.label_request_application') }}
            </h4>
        </div>
        <div class="modal-body">
            <form _lpchecked="1" action="#" method="post" @submit.prevent="validateBeforeSubmit">
                <div class="row">
                    <div class="col-md-12">
                        <div class="item">
                            <label class="w-full">
                                <span>{{ $t('editShort.form.label_name') }} <i>*</i></span>
                                <input type="text" name="name" v-validate="'required'" v-model="name"
                                       autocomplete="name"
                                       :data-vv-as="String($t('editShort.form.label_name')).toLowerCase()">
                                <div class="text-red">
                                    {{ errors.first('name') }}
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="item">
                            <label class="w-full">
                                <span>{{ $t('editShort.form.label_email') }} <i>*</i></span>
                                <input type="email" name="email" v-validate="'required'" v-model="email"
                                       autocomplete="email"
                                       :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                                <div class="text-red">
                                    {{ errors.first('email') }}
                                </div>
                            </label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="item">
                            <label class="w-full">
                                <span>{{ $t('editShort.form.label_age') }}<i>*</i></span>
                                <input type="number" name="age" v-validate="'required|decimal:0'" v-model="age"
                                       :min="minimumAge" :max="maximumAge"
                                       :data-vv-as="String($t('editShort.form.label_age')).toLowerCase()">
                                <div class="text-red">
                                    {{ errors.first('age') }}
                                </div>
                            </label>
                        </div>
                    </div>
                    <input type="hidden" name="timestamp" v-model="$root.timestamp">
                    <input type="hidden" name="programName" :value="programName">
                    <div class="col-xs-12">
                        <div v-if="successMessage.length > 0" class="alert alert-success animated fadeInDown">
                            <span>
                                {{ successMessage }}
                            </span>
                        </div>
                        <div v-if="errorMessages.length > 0" class="alert alert-danger animated fadeInDown">
                            <ul v-if="errorMessages.length > 1" class="mt-0">
                                <li class="w-100 text-left font-weight-normal" v-for="message in errorMessages">
                                    {{ message }}
                                </li>
                            </ul>
                            <span v-else>
                                {{ errorMessages[0] }}
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button class="comment-submit text-white" :disabled="submitting === true" @click.prevent="validateBeforeSubmit"
                    :class="submitted ? 'hidden' : ''">
                <span v-if="submitting === false">{{ $t('editShort.form.label_send_request') }}</span>
                <i class="fa fa-spinner fa-spin" v-if="submitting === true"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'ambassadorForm',
        props: ['programName', 'minimumAge', 'maximumAge'],
        data: function () {
            return {
                name: '',
                email: '',
                age: '',
                successMessage: '',
                errorMessages: [],
                submitting: false,
                submitted: false
            }
        },
        methods: {
            validateBeforeSubmit() {
                this.$validator.validateAll().then(result => {
                    let response;
                    if (result) {
                        this.submitting = true;

                        response = axios.post('/process/ambassadors.php', {
                                action: 'register',
                                name: this.name,
                                email: this.email,
                                age: this.age,
                                programName: this.programName,
                                timestamp: this.$root.timestamp
                            },
                            {
                                headers: {
                                    'Content-Type':
                                        'application/json'
                                }
                            }
                        )
                            .then(response => {
                                this.submitting = false;
                                if (response.data.success === true) {
                                    this.errorMessages = [];
                                    this.successMessage = this.$t('editShort.form.success_message');
                                    this.submitted = true;
                                } else {
                                    this.errorMessages = response.data.messages;
                                }
                            });
                    }
                });
            }
        }
    }
</script>

