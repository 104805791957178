'use strict';

/**
 * Smooth Scroll
 * TODO: see if this works
 */

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

/*
TODO: maybe there should be exceptions and callbacks for the linkSeletor.
	E.G. Bootstrap accordion might need an extra callback,
	or a particular set of divs is hidden and therefore shouldn't be scrolled to
 */

export function startNativeSmoothScroll(options) {

	let elementToAwait
		//, loopCounter
		//, smoothScrollLinks
		, scrollTarget;

	//Default options
	options.linkSelector = options.linkSelector || 'a[href*="#"]:not([href="#"]):not([href="#0"]):not([role="tab"])';
	options.offset = options.offset || 'a[href*="#"]:not([href="#"]):not([href="#0"]):not([role="tab"])';
	options.waitForElementWithId = options.waitForElementWithId || '';

	//smoothScrollLinks = document.querySelectorAll(options.linkSelector);

	//First, which element are we waiting for?
	if (options.waitForElementWithId.length > 0) {
		elementToAwait = document.getElementById(options.waitForElementWithId);
	}
	if (elementToAwait == null) {
		elementToAwait = window;
	}

	//When page or element is loaded, and there is a hash, scroll there
	elementToAwait.addEventListener('load', function () {
		if (window.location.hash.length > 0) {
			scrollTarget = document.getElementById(window.location.hash.substr(1));
			if (scrollTarget != null) {
				window.scrollBy({
					top: scrollTarget.getBoundingClientRect().top - options.offset,
					behavior: 'smooth'
				});
			}
		}
	});

	//Now that load event has been handled
	//we have time to add click events listeners.
	/* Edit: nuh uh
	for (loopCounter = 0; loopCounter < smoothScrollLinks.length; loopCounter++) {
		smoothScrollLinks.item(loopCounter).addEventListener('click', function (event) {
			let scrollTarget = document.getElementById(event.target.href.split('#').pop());
			if (scrollTarget != null) {
				event.preventDefault();
				window.scrollBy({
					top: scrollTarget.getBoundingClientRect().top - options.offset,
					behavior: 'smooth'
				});
			}
		});
	}
	 */
}


