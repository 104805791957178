import VueI18n from 'vue-i18n';
import getCurrentLanguage from '../utilities/getCurrentLanguage';
import axios from 'axios';

export default {
	/**
	 * Gets edit content from back-end, puts it in i!8n options object, and returns the object
	 */
	fetchEdits: function fetchEdits() {
		const i18nOptions = {
			locale: 'en'
			, messages: {
				en: {
					editShort: {
						form: {}
					}
					, tournamentForm: {}
				}
				, fr: {
					editShort: {
						form: {}
					}
					, tournamentForm: {}
				}
			}
		};

		return Promise.all([
			getCurrentLanguage().then(response => {
				i18nOptions.locale = response;
			})
			, axios.get('/api/v1/content/template-short-form-%25/').then(response => {
				i18nOptions.messages.en.editShort.form = response.data.data.content['en'].template.short.form;
				i18nOptions.messages.fr.editShort.form = response.data.data.content['fr'].template.short.form;
			})
			, axios.get('/api/v1/content/tournamentRegistration-%25/').then(response => {
				i18nOptions.messages.en.tournamentRegistration = response.data.data.content['en'].tournamentRegistration;
				i18nOptions.messages.fr.tournamentRegistration = response.data.data.content['fr'].tournamentRegistration;
			})
		])
			.then(() => {
				return Promise.resolve(new VueI18n(i18nOptions));
			});
	},

	/**
	 * Gets timestamp from back-end and returns it
	 */
	fetchTimestamp: function fetchTimestamp() {
		return axios.get('/api/v1/timestamp').then(response => {
			return Promise.resolve(response.data.data['timestamp']);
		});
	},
	/**
	 * Gets hockey experience options from back-end and returns them
	 */
	fetchHockeyExperience: function fetchHockeyExperience() {
		return axios.get('/api/v1/hockey-experiences/').then(response => {
			return Promise.resolve(response.data.data['hockey-experience']);
		});
	}
};
