<!--
TODO: where is success message?
TODO: `$eventContentDao = $dataContentDaoFactory->getDataContentDao('Event');
    $bursaryEvents = $eventContentDao->readAllVisibleForBursaryAvailable();`
    to get the list of visible events for which bursaries are available.
-->

<template>
    <div>
        <div id="accordion" class="panel-group leave-comment-wrap" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default">
                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="playerHeading">
                        <h2 class="panel-title">
                            <a role="button" href="#playerSection" aria-expanded="true" aria-controls="playerSection">
                                <!--TODO: APPLICANT BASIC INFORMATION (PLAYER)-->
                                {{ $t('editShort.form.title_applicant_basic_information') }}
                            </a>
                        </h2>
                    </div>
                </div>
                <div id="playerSection" class="panel-collapse collapse in" role="tabpanel"
                     aria-labelledby="playerHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('player')" data-vv-scope="player">
                            <div class="row flex flex-wrap">
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="playerFirstName">{{ $t('editShort.form.label_first_name') }}</label>
                                    <input type="text" id="playerFirstName" name="firstName" v-validate="'required'"
                                           v-model="player.firstName" autocomplete="fname"
                                           :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('player.firstName') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="playerMiddleName">{{ $t('editShort.form.label_middle_name') }}</label>
                                    <input type="text" id="playerMiddleName" name="middleName"
                                           v-model="player.middleName" autocomplete="mname">
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="playerLastName">{{ $t('editShort.form.label_last_name') }}</label>
                                    <input type="text" id="playerLastName" name="lastName" v-validate="'required'"
                                           v-model="player.lastName" autocomplete="lname"
                                           :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('player.lastName') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 item">
                                    <label for="playerAddress">{{ $t('editShort.form.label_address') }}</label>
                                    <input type="text" id="playerAddress" name="address" v-validate="'required'"
                                           v-model="player.address" autocomplete="street-address"
                                           :data-vv-as="String($t('editShort.form.label_address')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('player.address') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <label for="playerCity">{{ $t('editShort.form.label_city') }}</label>
                                    <input type="text" id="playerCity" name="city" v-validate="'required'"
                                           v-model="player.city" autocomplete="address-level2"
                                           :data-vv-as="String($t('editShort.form.label_city')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('player.city') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <label for="playerPostalCode">{{ $t('editShort.form.label_postal_code') }}</label>
                                    <input type="text" id="playerPostalCode" name="postalCode"
                                           v-validate="'required'"
                                           v-model="player.postalCode" autocomplete="postal-code"
                                           :data-vv-as="String($t('editShort.form.label_postal_code')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('player.postalCode') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <label for="playerCountry">{{ $t('editShort.form.label_country') }}</label>
                                    <select id="playerCountry" name="country"
                                            autocomplete="country"
                                            v-on:change="countryOnChange('player')"
                                            v-model="player.country"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_country')).toLowerCase()">
                                        <option v-for="country in countries" v-bind:value="country.ISOCode">
                                            {{ country[$validator.locale].mainTitle }}
                                        </option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('player.country') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <label for="playerRegion">{{ $t('editShort.form.label_state_province') }}</label>
                                    <select id="playerRegion" name="region" v-model="player.region"
                                            autocomplete="address-level1"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_state_province')).toLowerCase()">
                                        <option v-for="region in player.regions"
                                                :value="region.ISOCode">
                                            {{ region[$validator.locale].mainTitle }}
                                        </option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('player.region') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-6 item">
                                    <label for="playerPhone">
                                        {{ $t('editShort.form.label_phone') }}
                                        &nbsp;<a href="#only-with-permission">*</a>
                                    </label>
                                    <input type="text" id="playerPhone" name="phone" v-validate="'required'"
                                           v-model="player.phone" autocomplete="tel"
                                           :data-vv-as="String($t('editShort.form.label_phone')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('player.phone') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <label for="playerEmail">
                                        {{ $t('editShort.form.label_email') }}
                                        &nbsp;<a href="#only-with-permission">*</a>
                                    </label>
                                    <input type="email" id="playerEmail" name="email" v-validate="'required'"
                                           v-model="player.email" autocomplete="email"
                                           :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('player.email') }}
                                    </div>
                                </div>

                                <div id="only-with-permission" class="col-xs-12">
                                    <!--TODO: ONLY IF GRANTED PERMISSION BY PARENT/GUARDIAN-->
                                    {{ $t('editShort.form.note_only_with_parent_permission') }}
                                </div>

                                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                                <div class="col-xs-12">
                                    <div v-if="player.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="player.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in player.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ player.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button class="comment-submit text-white"
                                            :disabled="player.submitting === true">
                                    <span
                                        v-if="player.submitting === false">{{ $t('editShort.form.label_next') }}</span>
                                        <i class="fa fa-spinner fa-spin" v-if="player.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading panel-heading--disabled" role="tab" id="hockeyHeading">
                    <h2 class="panel-title">
                        <a class="collapsed" role="button" href="#hockeySection" aria-expanded="false"
                           aria-controls="hockeySection">
                            <!--TODO: APPLICANT HOCKEY INFORMATION-->
                            {{ $t('editShort.form.title_applicant_hockey_information') }}
                        </a>
                    </h2>
                </div>
                <div id="hockeySection" class="panel-collapse collapse" role="tabpanel" aria-labelledby="hockeyHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('hockey')"
                              data-vv-scope="hockey">
                            <div class="row flex flex-wrap">
                                <div class="col-xs-12 col-md-4 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_gender') }}</legend>
                                        <div>
                                            <label class="item float-left mr-8">
                                                <input type="radio" name="gender"
                                                       v-model="hockey.gender"
                                                       value="M" v-validate="'required'"
                                                       :data-vv-as="String($t('editShort.form.label_gender')).toLowerCase()">
                                                <span class="name">{{ $t('editShort.form.label_male') }}</span>
                                            </label>
                                            <label class="item float-left">
                                                <input type="radio" name="gender"
                                                       v-model="hockey.gender"
                                                       value="F">
                                                <span class="name">{{ $t('editShort.form.label_female') }}</span>
                                            </label>
                                        </div>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('gender') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="hockeyDateOfBirth">
                                        {{ $t('editShort.form.label_dateOfBirth') }}</label>
                                    <input type="text" id="hockeyDateOfBirth" name="dateOfBirth"
                                           class="datepicker" v-validate="'required'"
                                           data-vv-validate-on="change|select|blur"
                                           v-model="hockey.dateOfBirth"
                                           :data-vv-as="String($t('editShort.form.label_dateOfBirth')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('dateOfBirth') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4">
                                    <div class="row">
                                        <div class="col-xs-12 col-md-6 item">
                                            <label for="hockeyHeight">{{ $t('editShort.form.label_height') }}</label>
                                            <input type="text" id="hockeyHeight" name="height"
                                                   v-validate="'required'"
                                                   v-model="hockey.height"
                                                   :data-vv-as="String($t('editShort.form.label_height')).toLowerCase()">
                                            <div class="text-red">
                                                {{ errors.first('height') }}
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-md-6 item">
                                            <label for="hockeyWeight">{{ $t('editShort.form.label_weight') }}</label>
                                            <input type="text" id="hockeyWeight" name="weight"
                                                   v-validate="'required'"
                                                   v-model="hockey.weight"
                                                   :data-vv-as="String($t('editShort.form.label_weight')).toLowerCase()">
                                            <div class="text-red">
                                                {{ errors.first('weight') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_shootLeftRight') }}</legend>
                                        <label class="item float-left mr-8">
                                            <input type="radio" name="shootLeftRight"
                                                   value="L"
                                                   v-validate="'required'"
                                                   v-model="hockey.shootLeftRight"
                                                   :data-vv-as="String($t('editShort.form.label_shootLeftRight')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_left') }}</span>
                                        </label>
                                        <label class="item float-left">
                                            <input type="radio" name="shootLeftRight" value="R"
                                                   v-model="hockey.shootLeftRight">
                                            <span class="name">{{ $t('editShort.form.label_right') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('shootLeftRight') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <!--TODO: does this match the form?-->
                                    <label for="hockeyYearsPlayed">
                                        {{ $t('editShort.form.label_yearsPlayed') }}</label>
                                    <input type="number" id="hockeyYearsPlayed" name="yearsPlayed"
                                           v-validate="'required'"
                                           v-model="hockey.yearsPlayed"
                                           :data-vv-as="String($t('editShort.form.label_yearsPlayed')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('yearsPlayed') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="hockeyPosition">
                                        {{ $t('editShort.form.label_position') }}</label>
                                    <select id="hockeyPosition" name="position"
                                            v-validate="'required'"
                                            v-model="hockey.position"
                                            :data-vv-as="String($t('editShort.form.label_position')).toLowerCase()">
                                        <option value="Left Wing">{{ $t('editShort.form.label_leftWing') }}</option>
                                        <option value="Captain">{{ $t('editShort.form.label_captain') }}</option>
                                        <option value="Right Wing">{{ $t('editShort.form.label_rightWing') }}</option>
                                        <option value="Left Defense">{{ $t('editShort.form.label_leftDefense') }}
                                        </option>
                                        <option value="Right Defense">{{ $t('editShort.form.label_rightDefense') }}
                                        </option>
                                        <option value="Goalie">{{ $t('editShort.form.label_goalie') }}</option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('position') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-4 item">
                                    <label for="hockeyLevel">{{ $t('editShort.form.label_hockeyLevel') }}</label>
                                    <select name="hockeyLevel" id="hockeyLevel"
                                            v-model="hockey.hockeyLevel"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_hockeyLevel')).toLowerCase()">
                                        <option value="Novice">{{ $t('editShort.form.label_novice') }}</option>
                                        <option value="Bantam">{{ $t('editShort.form.label_bantam') }}</option>
                                        <option value="School Team">{{ $t('editShort.form.label_schoolTeam') }}</option>
                                        <option value="Peewee">{{ $t('editShort.form.label_peewee') }}</option>
                                        <option value="Atom">{{ $t('editShort.form.label_atom') }}</option>
                                        <option value="Club Hockey">{{ $t('editShort.form.label_clubHockey') }}</option>
                                        <option value="Other">{{ $t('editShort.form.label_other') }}</option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('hockeyLevel') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-4 item">
                                    <label for="hockeyCalibre">{{ $t('editShort.form.label_hockeyCalibre') }}</label>
                                    <select name="hockeyCalibre" id="hockeyCalibre"
                                            v-model="hockey.hockeyCalibre"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_hockeyCalibre')).toLowerCase()">
                                        <option value="A">A</option>
                                        <option value="AA">AA</option>
                                        <option value="AAA">AAA</option>
                                        <option value="B">B</option>
                                        <option value="BB">BB</option>
                                        <option value="C">C</option>
                                        <option value="CC">CC</option>
                                        <option value="Other">{{ $t('editShort.form.label_other') }}</option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('hockeyCalibre') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-4 item">
                                    <label for="hockeyComfortLevel">{{ $t('editShort.form.label_hockeyComfortLevel')
                                        }}</label>
                                    <select name="hockeyComfortLevel" id="hockeyComfortLevel"
                                            v-model="hockey.comfortLevel"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_hockeyComfortLevel')).toLowerCase()">
                                        <option value="1st line">{{$t('editShort.form.label_1stLine')}}</option>
                                        <option value="2nd line">{{$t('editShort.form.label_2ndLine')}}</option>
                                        <option value="3rd line">{{$t('editShort.form.label_3rdLine')}}</option>
                                        <option value="Power Play">{{$t('editShort.form.label_powerPlay')}}</option>
                                        <option value="Penalty kill">{{$t('editShort.form.label_penaltyKill')}}</option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('hockeyComfortLevel') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-4 item">
                                    <label for="wantsToImprove">{{ $t('editShort.form.label_wantsToImprove') }}</label>
                                    <select name="wantsToImprove" id="wantsToImprove"
                                            v-model="hockey.wantsToImprove"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_wantsToImprove')).toLowerCase()">
                                        <option value="Skating">{{$t('editShort.form.label_skating')}}</option>
                                        <option value="Passing">{{$t('editShort.form.label_passing')}}</option>
                                        <option value="Shooting">{{$t('editShort.form.label_shooting')}}</option>
                                        <option value="Awareness">{{$t('editShort.form.label_awareness')}}</option>
                                        <option value="Other">{{$t('editShort.form.label_other')}}</option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('wantsToImprove') }}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-xs-12">
                                    <div v-if="hockey.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="hockey.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in hockey.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ hockey.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button class="comment-submit text-white"
                                            :disabled="hockey.submitting === true">
                                    <span
                                        v-if="hockey.submitting === false">
                                        {{ $t('editShort.form.label_next') }}</span>
                                        <i class="fa fa-spinner fa-spin"
                                           v-if="hockey.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading panel-heading--disabled" role="tab" id="parentHeading">
                    <h2 class="panel-title">
                        <a class="collapsed" role="button" href="#parentSection" aria-expanded="false"
                           aria-controls="parentSection">
                            {{ $t('editShort.form.title_parent_information') }}
                        </a>
                    </h2>
                </div>
                <div id="parentSection" class="panel-collapse collapse" role="tabpanel"
                     aria-labelledby="parentHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('parent')"
                              data-vv-scope="parent">

                            <div class="row">
                                <div class="col-xs-12 col-md-4">
                                    <label for="parentRelation" class="font-weight-normal">
                                        {{ $t('editShort.form.label_relation') }}</label>
                                    <select id="parentRelation" name="relation"
                                            v-model="parent.relation"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_relation')).toLowerCase()">
                                        <option value="Father">
                                            {{ $t('editShort.form.label_father') }}
                                        </option>
                                        <option value="Mother">
                                            {{ $t('editShort.form.label_mother') }}
                                        </option>
                                        <option value="Guardian">
                                            {{ $t('editShort.form.label_guardian') }}
                                        </option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('relation') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-8 text-right">
                                    <button v-on:click="parentCopyAddress()" class="comment-submit text-white"
                                            type="button">
                                        {{ $t('editShort.form.label_copy_address_from_contact') }}
                                    </button>
                                </div>
                            </div>

                            <div class="row flex flex-wrap">

                                <div class="col-xs-12 col-md-4 item">
                                    <label for="parentFirstName">{{ $t('editShort.form.label_first_name')
                                        }}</label>
                                    <input type="text" id="parentFirstName"
                                           name="firstName"
                                           v-validate="'required'"
                                           v-model="parent.firstName" autocomplete="fname"
                                           :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('firstName') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="parentLastName">
                                        {{$t('editShort.form.label_last_name')}}</label>
                                    <input type="text" id="parentLastName"
                                           name="lastName"
                                           v-validate="'required'"
                                           v-model="parent.lastName" autocomplete="lname"
                                           :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('lastName') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="parentEmail">{{ $t('editShort.form.label_email')
                                        }}</label>
                                    <input type="email" id="parentEmail" name="email"
                                           v-validate="'required'"
                                           v-model="parent.email" autocomplete="email"
                                           :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('email') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="parentAddress">
                                        {{ $t('editShort.form.label_address') }}
                                        ({{ $t('editShort.form.label_if_different_from_player') }})
                                    </label>
                                    <input type="text" id="parentAddress" name="address"
                                           v-validate="'required'"
                                           v-model="parent.address"
                                           autocomplete="street-address"
                                           :data-vv-as="String($t('editShort.form.label_address')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('address') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="parentCity">{{ $t('editShort.form.label_city') }}</label>
                                    <input type="text" id="parentCity" name="city"
                                           v-validate="'required'"
                                           v-model="parent.city"
                                           autocomplete="address-level2"
                                           :data-vv-as="String($t('editShort.form.label_city')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('city') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="parentCountry">
                                        {{ $t('editShort.form.label_country') }}</label>
                                    <select id="parentCountry" name="country"
                                            autocomplete="country"
                                            v-on:change="countryOnChange('parent')"
                                            v-model="parent.country"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_country')).toLowerCase()">
                                        <option v-for="country in countries" v-bind:value="country.ISOCode">
                                            {{ country[$validator.locale].mainTitle }}
                                        </option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('country') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="parentRegion">{{
                                        $t('editShort.form.label_state_province')
                                        }}</label>
                                    <select id="parentRegion" name="region"
                                            v-model="parent.region"
                                            autocomplete="address-level1"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_state_province')).toLowerCase()">
                                        <option v-for="region in parent.regions"
                                                :value="region.ISOCode">
                                            {{ region[$validator.locale].mainTitle }}
                                        </option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('region') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3 item">
                                    <label for="parentPostalCode">{{
                                        $t('editShort.form.label_postal_code')
                                        }}</label>
                                    <input type="text" id="parentPostalCode"
                                           name="postalCode"
                                           v-validate="'required'"
                                           v-model="parent.postalCode"
                                           autocomplete="postal-code"
                                           :data-vv-as="String($t('editShort.form.label_postal_code')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('postalCode') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3 item">
                                    <label for="parentCellPhone">
                                        {{ $t('editShort.form.label_business_cell_phone') }}</label>
                                    <input type="text" id="parentCellPhone"
                                           name="cellPhone"
                                           v-validate="'required'"
                                           v-model="parent.cellPhone" autocomplete="tel"
                                           :data-vv-as="String($t('editShort.form.label_business_cell_phone')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('cellPhone') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-3 item">
                                    <label for="parentHomePhone">{{ $t('editShort.form.label_home_phone')
                                        }}</label>
                                    <input type="text" id="parentHomePhone"
                                           name="homePhone"
                                           v-validate="'required'"
                                           v-model="parent.homePhone" autocomplete="tel"
                                           :data-vv-as="String($t('editShort.form.label_home_phone')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('homePhone') }}
                                    </div>
                                </div>
                            </div>

                            <div class="row flex flex-wrap">
                                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                                <div class="col-xs-12">
                                    <div v-if="parent.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="parent.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in parent.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ parent.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button class="comment-submit text-white"
                                            :disabled="parent.submitting === true">
                                    <span v-if="parent.submitting === false">
                                        {{ $t('editShort.form.label_next') }}</span>
                                        <i class="fa fa-spinner fa-spin"
                                           v-if="parent.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading panel-heading--disabled" role="tab" id="questionnaireHeading">
                    <h2 class="panel-title">
                        <a class="collapsed" role="button" href="#questionnaireSection" aria-expanded="false"
                           aria-controls="questionnaireSection">
                            {{ $t('editShort.form.title_questionnaire') }}
                        </a>
                    </h2>
                </div>
                <div id="questionnaireSection" class="panel-collapse collapse" role="tabpanel"
                     aria-labelledby="questionnaireHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('questionnaire')"
                              data-vv-scope="questionnaire">
                            <div class="row">
                                <div class="col-xs-12 item">
                                    <fieldset>
                                        <legend>{{$t('editShort.form.label_employmentStatus')}}</legend>
                                        <label class="item">
                                            <input type="radio" value="Employed"
                                                   id="employed" name="employmentStatus"
                                                   v-model="questionnaire.employmentStatus"
                                                   v-validate="'required'"
                                                   :data-vv-as="String($t('editShort.form.label_employmentStatus')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_employed') }}</span>
                                        </label>
                                        <label class="item">
                                            <input type="radio" value="unemployed"
                                                   id="unemployed" name="employmentStatus"
                                                   v-model="questionnaire.employmentStatus">
                                            <span class="name">{{ $t('editShort.form.label_unemployed ') }}</span>
                                        </label>
                                        <label class="item">
                                            <input type="radio" value="Self-employed"
                                                   id="selfEmployed" name="employmentStatus"
                                                   v-model="questionnaire.employmentStatus">
                                            <span class="name">{{ $t('editShort.form.label_selfEmployed') }}</span>
                                        </label>
                                        <label class="item">
                                            <input type="radio" value="Part-time"
                                                   id="partTime" name="employmentStatus"
                                                   v-model="questionnaire.employmentStatus">
                                            <span class="name">{{ $t('editShort.form.label_partTime') }}</span>
                                        </label>
                                        <label class="item">
                                            <input type="radio" value="Other"
                                                   id="otherEmployment" name="employmentStatus"
                                                   v-model="questionnaire.employmentStatus">
                                            <span class="name">{{ $t('editShort.form.label_other') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('employmentStatus') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <fieldset>
                                        <legend>{{$t('editShort.form.label_income')}}</legend>
                                        <label class="item">
                                            <input type="radio" value="< $30,000"
                                                   id="lessThan30000" name="income"
                                                   v-model="questionnaire.income"
                                                   v-validate="'required'"
                                                   :data-vv-as="String($t('editShort.form.label_income')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_lessThan30000') }}</span>
                                        </label>
                                        <label class="item">
                                            <input type="radio" value="$30-45,000"
                                                   id="30to45000" name="income"
                                                   v-model="questionnaire.income">
                                            <span class="name">{{ $t('editShort.form.label_30to45000') }}</span>
                                        </label>
                                        <label class="item">
                                            <input type="radio" value="$45-60,000"
                                                   id="45to60000" name="income"
                                                   v-model="questionnaire.income">
                                            <span class="name">{{ $t('editShort.form.label_45to60000') }}</span>
                                        </label>
                                        <label class="item">
                                            <input type="radio" value="$60-75,000"
                                                   id="60to75000" name="income"
                                                   v-model="questionnaire.income">
                                            <span class="name">{{ $t('editShort.form.label_60to75000') }}</span>
                                        </label>
                                        <label class="item">
                                            <input type="radio" value="$75,000 >"
                                                   id="over75000" name="income"
                                                   v-model="questionnaire.income">
                                            <span class="name">{{ $t('editShort.form.label_over75000') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('income') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <fieldset>
                                        <legend>
                                            {{$t('editShort.form.label_playerLivesWith')}}<br>
                                            <small>{{$t('editShort.form.pleaseCheckAllThatApply')}}</small>
                                        </legend>
                                        <!--father sibling grandparent family member other-->
                                        <input type="checkbox" value="Mother" class="filter-check"
                                               id="livesWithMother" name="playerLivesWith"
                                               v-model="questionnaire.playerLivesWith"
                                               v-validate="'required'"
                                               :data-vv-as="String($t('editShort.form.label_playerLivesWith')).toLowerCase()">
                                        <label for="livesWithMother">
                                            {{ $t('editShort.form.label_mother') }}
                                        </label>
                                        <input type="radio" value="Father" class="filter-check"
                                               id="livesWithFather" name="playerLivesWith"
                                               v-model="questionnaire.playerLivesWith"
                                               v-validate="'required'">
                                        <label for="livesWithFather">
                                            {{ $t('editShort.form.label_father') }}
                                        </label>
                                        <input type="radio" value="Sibling" class="filter-check"
                                               id="livesWithSibling" name="playerLivesWith"
                                               v-model="questionnaire.playerLivesWith"
                                               v-validate="'required'">
                                        <label for="livesWithSibling">
                                            {{ $t('editShort.form.label_sibling') }}
                                        </label>
                                        <input type="radio" value="Grandparent" class="filter-check"
                                               id="livesWithGrandparent" name="playerLivesWith"
                                               v-model="questionnaire.playerLivesWith"
                                               v-validate="'required'">
                                        <label for="livesWithGrandparent">
                                            {{ $t('editShort.form.label_grandparent') }}
                                        </label>
                                        <input type="radio" value="Family Member" class="filter-check"
                                               id="livesWithFamilyMember" name="playerLivesWith"
                                               v-model="questionnaire.playerLivesWith"
                                               v-validate="'required'">
                                        <label for="livesWithFamilyMember">
                                            {{ $t('editShort.form.label_familyMember') }}
                                        </label>
                                        <input type="radio" value="Other" class="filter-check"
                                               id="livesWithOther" name="playerLivesWith"
                                               v-model="questionnaire.playerLivesWith"
                                               v-validate="'required'">
                                        <label for="livesWithOther">
                                            {{ $t('editShort.form.label_other') }}
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('playerLivesWith') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 item">
                                    <fieldset>
                                        <legend>{{$t('editShort.form.label_otherFunding')}}</legend>
                                        <input type="radio" value="No"
                                               id="noOtherFunding" name="otherFunding"
                                               v-model="questionnaire.otherFunding"
                                               v-validate="'required'">
                                        <label for="noOtherFunding">
                                            {{ $t('editShort.form.label_no') }}
                                        </label>
                                        <input type="radio" value="Yes"
                                               id="yesOtherFunding" name="otherFunding"
                                               v-model="questionnaire.otherFunding"
                                               v-validate="'required'">
                                        <label for="yesOtherFunding">
                                            {{ $t('editShort.form.label_yes') }}
                                        </label>
                                        <div>
                                            <label for="specifyOtherFunding">
                                                {{ $t('editShort.form.label_pleaseSpecify') }}
                                            </label>
                                            <input type="text" id="specifyOtherFunding" name="specifyOtherFunding"
                                                   v-model="questionnaire.specifyOtherFunding"
                                                   v-validate="'required_if:otherFunding,Yes'">
                                        </div>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('otherFunding') }}
                                        {{ errors.first('specifyOtherFunding') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 item">
                                    <fieldset>
                                        <legend>{{$t('editShort.form.label_additionalSummerPrograms')}}</legend>
                                        <input type="radio" value="No"
                                               id="noAdditionalSummerPrograms" name="additionalSummerPrograms"
                                               v-model="questionnaire.additionalSummerPrograms"
                                               v-validate="'required'">
                                        <label for="noAdditionalSummerPrograms">
                                            {{ $t('editShort.form.label_no') }}
                                        </label>
                                        <input type="radio" value="Yes"
                                               id="yesAdditionalSummerPrograms" name="additionalSummerPrograms"
                                               v-model="questionnaire.additionalSummerPrograms"
                                               v-validate="'required'">
                                        <label for="yesAdditionalSummerPrograms">
                                            {{ $t('editShort.form.label_yes') }}
                                        </label>
                                        <div>
                                            <label for="specifyAdditionalSummerPrograms">
                                                {{ $t('editShort.form.label_pleaseSpecify') }}
                                            </label>
                                            <input type="text" id="specifyAdditionalSummerPrograms"
                                                   name="specifyAdditionalSummerPrograms"
                                                   v-model="questionnaire.specifyAdditionalSummerPrograms"
                                                   v-validate="'required_if:additionalSummerPrograms,Yes'">
                                        </div>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('additionalSummerPrograms') }}
                                        {{ errors.first('specifyAdditionalSummerPrograms') }}
                                    </div>
                                </div>

                            </div>
                            <div class="row flex flex-wrap">
                                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                                <div class="col-xs-12">
                                    <div v-if="questionnaire.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="questionnaire.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in questionnaire.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ questionnaire.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button class="comment-submit text-white"
                                            :disabled="questionnaire.submitting === true">
                                    <span v-if="questionnaire.submitting === false">
                                        {{ $t('editShort.form.label_next') }}</span>
                                        <i class="fa fa-spinner fa-spin"
                                           v-if="questionnaire.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading panel-heading--disabled" role="tab" id="playerQuestionnaireHeading">
                    <h2 class="panel-title">
                        <a class="collapsed" role="button" href="#playerQuestionnaireSection" aria-expanded="false"
                           aria-controls="playerQuestionnaireSection">
                            {{ $t('editShort.form.title_playerQuestionnaire') }}
                        </a>
                    </h2>
                </div>
                <div id="playerQuestionnaireSection" class="panel-collapse collapse" role="tabpanel"
                     aria-labelledby="playerQuestionnaireHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('playerQuestionnaire')"
                              data-vv-scope="playerQuestionnaire">
                            <div class="row">
                                <div class="col-xs-12 item">
                                    <label for="whyWantAttend">
                                        {{$t('editShort.form.label_whyWantAttend')}}</label>
                                    <textarea id="whyWantAttend" name="whyWantAttend"
                                              v-validate="'required'"
                                              v-model="playerQuestionnaire.whyWantAttend"
                                              :data-vv-as="String($t('editShort.form.label_whyWantAttend')).toLowerCase()">
                                    </textarea>
                                    <div class="text-red">
                                        {{ errors.first('whyWantAttend') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label for="hardestMoment">
                                        {{$t('editShort.form.label_hardestMoment')}}</label>
                                    <textarea id="hardestMoment" name="hardestMoment"
                                              v-validate="'required'"
                                              v-model="playerQuestionnaire.hardestMoment"
                                              :data-vv-as="String($t('editShort.form.label_hardestMoment')).toLowerCase()">
                                    </textarea>
                                    <div class="text-red">
                                        {{ errors.first('hardestMoment') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label for="howOldWhenT1D">
                                        {{$t('editShort.form.label_howOldWhenT1D')}}</label>
                                    <input id="howOldWhenT1D" name="howOldWhenT1D" type="number"
                                           v-validate="'required'"
                                           v-model="playerQuestionnaire.howOldWhenT1D"
                                           :data-vv-as="String($t('editShort.form.label_howOldWhenT1D')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('howOldWhenT1D') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <fieldset>
                                        <legend>{{$t('editShort.form.label_haveFriendsWithT1D')}}</legend>
                                        <input id="haveFriendsWithT1DYes" name="haveFriendsWithT1D" type="radio"
                                               v-validate="'required'"
                                               v-model="playerQuestionnaire.haveFriendsWithT1D"
                                               :data-vv-as="String($t('editShort.form.label_haveFriendsWithT1D')).toLowerCase()">
                                        <label for="haveFriendsWithT1DYes">
                                            {{$t('editShort.form.label_yes')}}</label>
                                        <input id="haveFriendsWithT1DNo" name="haveFriendsWithT1D" type="radio"
                                               v-validate="'required'"
                                               v-model="playerQuestionnaire.haveFriendsWithT1D">
                                        <label for="haveFriendsWithT1DNo">
                                            {{$t('editShort.form.label_no')}}</label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('haveFriendsWithT1D') }}
                                    </div>
                                </div>

                                <div class="col-xs-12 item">
                                    <label for="dreamJob">
                                        {{$t('editShort.form.label_dreamJob')}}</label>
                                    <textarea id="dreamJob" name="dreamJob"
                                              v-validate="'required'"
                                              v-model="playerQuestionnaire.dreamJob"
                                              :data-vv-as="String($t('editShort.form.label_dreamJob')).toLowerCase()">
                                    </textarea>
                                    <div class="text-red">
                                        {{ errors.first('dreamJob') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row flex flex-wrap">
                                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                                <div class="col-xs-12">
                                    <div v-if="playerQuestionnaire.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="playerQuestionnaire.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in playerQuestionnaire.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ playerQuestionnaire.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button class="comment-submit text-white"
                                            :disabled="playerQuestionnaire.submitting === true">
                                    <span v-if="playerQuestionnaire.submitting === false">
                                        {{ $t('editShort.form.label_next') }}</span>
                                        <i class="fa fa-spinner fa-spin"
                                           v-if="playerQuestionnaire.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading panel-heading--disabled" role="tab" id="parentQuestionnaireHeading">
                    <h2 class="panel-title">
                        <a class="collapsed" role="button" href="#parentQuestionnaireSection" aria-expanded="false"
                           aria-controls="parentQuestionnaireSection">
                            {{ $t('editShort.form.title_parentQuestionnaire') }}
                        </a>
                    </h2>
                </div>
                <div id="parentQuestionnaireSection" class="panel-collapse collapse" role="tabpanel"
                     aria-labelledby="parentQuestionnaireHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('parentQuestionnaire')"
                              data-vv-scope="parentQuestionnaire">
                            <div class="row">
                                <div class="col-xs-12 item">
                                    <fieldset>
                                        <legend>{{$t('editShort.form.label_paySmallerFee')}}</legend>
                                        <input id="paySmallerFeeYes" name="paySmallerFee" type="radio"
                                               value="1"
                                               v-validate="'required'"
                                               v-model="parentQuestionnaire.paySmallerFee"
                                               :data-vv-as="String($t('editShort.form.label_paySmallerFee')).toLowerCase()">
                                        <label for="paySmallerFeeYes">
                                            {{$t('editShort.form.label_yes')}}</label>
                                        <input id="paySmallerFeeNo" name="paySmallerFee" type="radio"
                                               value="0"
                                               v-validate="'required'"
                                               v-model="playerQuestionnaire.paySmallerFee">
                                        <label for="paySmallerFeeNo">
                                            {{$t('editShort.form.label_no')}}</label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label for="whyDeservesToAttend">
                                        {{$t('editShort.form.label_whyDeservesToAttend')}}</label>
                                    <textarea id="whyDeservesToAttend" name="whyDeservesToAttend"
                                              v-validate="'required'"
                                              v-model="parentQuestionnaire.whyDeservesToAttend"
                                              :data-vv-as="String($t('editShort.form.label_whyDeservesToAttend')).toLowerCase()">
                                    </textarea>
                                    <div class="text-red">
                                        {{ errors.first('whyDeservesToAttend') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <fieldset>
                                        <legend>{{$t('editShort.form.label_pleaseSelectEvent')}}</legend>
                                        <!--TODO: events from API-->
                                        <label for="paySmallerFeeYes">
                                            <!--TODO: name, date, city, state-->
                                        </label>
                                        <!--TODO: only put :data-vv-as if first (might work anyways though) -->
                                        <input id="eventID" name="eventID" type="radio"
                                               value=""
                                               v-validate="'required'"
                                               v-model="parentQuestionnaire.eventID"
                                               :data-vv-as="String($t('editShort.form.label_event')).toLowerCase()">
                                        <!--TODO: editShort.form.label_event = event-->
                                    </fieldset>
                                    <div class="text-red">
                                        {{ errors.first('eventID') }}
                                    </div>
                                </div>
                            </div>
                            <div class="row flex flex-wrap">
                                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                                <div class="col-xs-12">
                                    <div v-if="parentQuestionnaire.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="parentQuestionnaire.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in parentQuestionnaire.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ parentQuestionnaire.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button class="comment-submit text-white"
                                            :disabled="parentQuestionnaire.submitting === true">
                                    <span v-if="parentQuestionnaire.submitting === false">
                                        {{ $t('editShort.form.label_send') }}</span>
                                        <i class="fa fa-spinner fa-spin"
                                           v-if="parentQuestionnaire.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="errorMessages.length > 0"
             class="alert alert-danger animated fadeInDown">
            <ul v-if="errorMessages.length > 1" class="mt-0">
                <li class="w-100 text-left font-weight-normal"
                    v-for="message in errorMessages">
                    {{ message }}
                </li>
            </ul>
            <span v-else>{{ errorMessages[0] }}</span>
        </div>
        <div v-if="successMessage.length > 0" class="alert alert-success animated fadeInDown">
            <span>{{ successMessage }}</span>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import 'pikaday/css/pikaday.css';

    import Pikaday from 'pikaday';

    let bsn = require('bootstrap.native');
    let playerCollapse, hockeyCollapse, parentCollapse,
        questionnaireCollapse, playerQuestionnaireCollapse, parentQuestionnaireCollapse, playerDateOfBirthPicker;

    let now = new Date();
    let currencyFormat;

    export default {
        name: 'eventRegistration'
        , props: ['eventCanonicalLink', 'eventId', 'languageIndex']
        , data: function () {
            let data = {
                player: {
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    address: '',
                    city: '',
                    country: '',
                    region: '',
                    postalCode: '',
                    submitting: false,
                    submitted: false,
                    errorMessages: [],
                    regions: []
                }
                , hockey: {
                    gender: '',
                    dateOfBirth: '',
                    height: '',
                    weight: '',
                    shootLeftRight: '',
                    yearsPlayed: '',
                    position: '',
                    hockeyLevel: '', //novice, peewee, etc. (same values as hockey experience table, but pick only one
                    hockeyCaliber: '', //A, AA, AAA, B, BB, C, CC, Other
                    comfortLevel: '', //1st line, 2nd line, 3rd line, power play, penalty kill
                    wantsToImprove: '', // skating, passing, shooting, awareness, other
                    submitting: false,
                    submitted: false,
                    errorMessages: [],
                }
                , parent: {
                    relation: ''
                    , firstName: ''
                    , middleName: ''
                    , lastName: ''
                    , cellPhone: ''
                    , email: ''
                    , address: ''
                    , city: ''
                    , country: ''
                    , region: ''
                    , postalCode: ''
                    , submitting: false
                    , submitted: false
                    , errorMessages: []
                    , regions: []
                }
                , questionnaire: {
                    employmentStatus: '' //employed unemployed self-employed part-time other
                    , income: '' //< $30,000 $30-45,000 $45-60,000 $60-75,000 $75,000 >
                    , playerLivesWith: '' //mother father sibling grandparent family member other
                    , otherFunding: '' //This will either be 'no' or 'this program name, that program name'
                    , specifyOtherFunding: ''
                    , additionalSummerPrograms: '' //This will either be 'no' or 'this camp name, that program name'
                    , specifyAdditionalSummerPrograms: ''
                    , submitting: false
                    , submitted: false
                    , errorMessages: []
                }
                , playerQuestionnaire: {
                    whyWantAttend: ''
                    , hardestMoment: ''
                    , howOldWhenT1D: ''
                    , haveFriendsWithT1D: ''
                    , dreamJob: ''
                    , submitting: false
                    , submitted: false
                    , errorMessages: []
                }
                , parentQuestionnaire: {
                    paySmallerFee: '' //0 or 1
                    , whyDeservesToAttend: ''
                    , eventID: ''
                    , submitting: false
                    , submitted: false
                    , errorMessages: []
                }
                , countries: {}
                , regions: {}
                , errorMessages: []
                , successMessage: ''
                , datePicker: ''
                , pikadayOptions: {}
            };
            currencyFormat = new Intl.NumberFormat(('en-CA'), {
                style: 'currency', currency: 'CAD'
            });
            if (this.languageIndex === 'fr') {
                data.pikadayOptions.i18n = {
                    previousMonth: 'Le mois précédent',
                    nextMonth: 'Le mois prochain',
                    months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
                    weekdays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
                    weekdaysShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']
                };
                data.pikadayOptions.firstDay = 1;
                new Intl.NumberFormat(('fr-CA'), {
                    style: 'currency', currency: 'CAD'
                });
            }
            return data;
        }
        , created() {
            axios.get('/api/v1/geo-countries/')
                .then(response => {
                    this.countries = Object.values(response.data.data['geo-country']);
                    return axios.get('/api/v1/geo-country-regions/')
                })
                .then(response => {
                    this.regions = Object.values(response.data.data['geo-country-region']);
                    return Promise.resolve();
                })
                .then(() => {
                    return axios.post(
                        '/process/bursaryApplication.php'
                        , {action: 'retrieveFormData'}
                        , {headers: {'Content-Type': 'application/json'}}
                    );
                })
                .then(response => {

                    //Load form where user left off
                    //TODO: redo for this form
                    if (response.data.data !== null) {
                        if ('player' in response.data.data) {
                            this.player = Object.assign(this.player, response.data.data.player);
                            this.countryOnChange('player');
                            this.player.region = response.data.data.player.region;
                        }
                        if ('hockey' in response.data.data) {
                            this.hockey = Object.assign(this.hockey, response.data.data.hockey);
                        }
                        if ('parent' in response.data.data) {
                            this.parent = Object.assign(this.parent, response.data.data.parent);
                            this.countryOnChange('parent');
                            this.parent.region = response.data.data.parent.region;
                        }
                        if ('questionnaire' in response.data.data) {
                            this.questionnaire = Object.assign(this.questionnaire, response.data.data.questionnaire);
                        }
                        if ('playerQuestionnaire' in response.data.data) {
                            this.playerQuestionnaire = Object.assign(
                                this.playerQuestionnaire, response.data.data.playerQuestionnaire);
                        }
                        if ('parentQuestionnaire' in response.data.data) {
                            this.parentQuestionnaire = Object.assign(
                                this.parentQuestionnaire, response.data.data.parentQuestionnaire);
                        }
                    }

                });
        }
        , mounted() {
            let hockeyPlayer = this.hockey;

            this.$nextTick(() => {
                playerCollapse = new bsn.Collapse(document.querySelector('#playerHeading a'), {
                    parent: '#accordion'
                });
                hockeyCollapse = new bsn.Collapse(document.querySelector('#hockeyHeading a'), {
                    parent: '#accordion'
                });
                parentCollapse = new bsn.Collapse(document.querySelector('#parentHeading a'), {
                    parent: '#accordion'
                });
                questionnaireCollapse = new bsn.Collapse(document.querySelector('#questionnaireHeading a'), {
                    parent: '#accordion'
                });
                playerQuestionnaireCollapse = new bsn.Collapse(document.querySelector('#playerQuestionnaireHeading a'), {
                    parent: '#accordion'
                });
                parentQuestionnaireCollapse = new bsn.Collapse(document.querySelector('#parentQuestionnaireHeading a'), {
                    parent: '#accordion'
                });

                //Why an extra 2 years?
                //- 1 extra year for someone who super-loves hockey
                //- 1 extra year for a cheap bug fix
                this.pikadayOptions.yearRange = [now.getFullYear() - 19, now.getFullYear() - 6]
                this.pikadayOptions.field = document.getElementById('hockeyDateOfBirth');
                this.pikadayOptions.onSelect = function (date) {
                    hockeyPlayer.dateOfBirth = date;
                };

                document.getElementById('hockeySection').addEventListener('show.bs.collapse', () => {
                    playerDateOfBirthPicker = new Pikaday(this.pikadayOptions);
                });

            });
        }
        , methods: {
            countryOnChange(section) {
                let selectedCountry = this.countries.find(country => {
                    return country.ISOCode === this[section].country;
                });
                this[section].regions = this.regions.filter(region => {
                    return selectedCountry.ID === region.geographyCountryID;
                });
            }
            , validatePlayerDateOfBirth() {
                this.$validator.validate(
                    'players.dateOfBirth'
                    , this.hockey.dateOfBirth
                )
                    .then(response => {
                        console.log(response);
                    });
            }
            , parentCopyAddress() {
                this.parent.email = this.player.email;
                this.parent.address = this.player.address;
                this.parent.city = this.player.city;
                this.parent.country = this.player.country;
                this.countryOnChange('parent');
                this.parent.region = this.player.region;
                this.parent.postalCode = this.player.postalCode;
                this.parent.cellPhone = this.player.phone
            }
            , submit(scope) {
                this.$validator.validateAll(scope).then(result => {
                    if (result) {
                        switch (scope) {
                            case 'player':
                                this.submitPlayer();
                                break;
                            case 'hockey':
                                this.submitHockey();
                                break;
                            case 'parent':
                                this.submitParent();
                                break;
                            case 'questionnaire':
                                this.submitQuestionnaire();
                                break;
                            case 'playerQuestionnaire':
                                this.submitPlayerQuestionnaire();
                                break;
                            case 'parentQuestionnaire':
                                this.submitParentQuestionnaire();
                                break;
                        }
                    }
                });
            }
            , submitPlayer() {
                let response;
                this.player.submitting = true;
                response = axios.post('/process/bursaryApplication.php'
                    , {
                        action: 'player'
                        , timestamp: this.$root.timestamp
                        , firstName: this.player.firstName
                        , middleName: this.player.middleName
                        , lastName: this.player.lastName
                        , phone: this.player.phone
                        , email: this.player.email
                        , address: this.player.address
                        , city: this.player.city
                        , country: this.player.country
                        , region: this.player.region
                        , postalCode: this.player.postalCode
                    }
                    , {headers: {'Content-Type': 'application/json'}}
                )
                    .then(response => {
                        this.player.submitting = false;
                        if (response.data.success === true) {
                            this.player.errorMessages = [];
                            playerCollapse.hide();
                            document.getElementById('playerSection').classList.remove('in');
                            document.getElementById('hockeyHeading').classList.remove('panel-heading--disabled');
                            hockeyCollapse.show();
                        } else {
                            this.player.errorMessages = response.data.messages;
                        }
                    });
            }
            , submitHockey() {
                let response;
                this.hockey.submitting = true;
                response = axios.post('/process/bursaryApplication.php'
                    , {
                        action: 'hockey'
                        , timestamp: this.$root.timestamp
                        , gender: this.hockey.gender
                        , dateOfBirth: this.hockey.dateOfBirth
                        , height: this.hockey.height
                        , weight: this.hockey.weight
                        , shootLeftRight: this.hockey.shootLeftRight
                        , yearsPlayed: this.hockey.yearsPlayed
                        , position: this.hockey.position
                        , hockeyLevel: this.hockey.hockeyLevel
                        , hockeyCaliber: this.hockey.hockeyCaliber
                        , comfortLevel: this.hockey.comfortLevel
                        , wantsToImprove: this.hockey.wantsToImprove
                    }
                    , {headers: {'Content-Type': 'application/json'}}
                )
                    .then(response => {
                        this.hockey.submitting = false;
                        if (response.data.success === true) {
                            this.hockey.errorMessages = [];
                            hockeyCollapse.hide();
                            document.getElementById('hockeySection').classList.remove('in');
                            document.getElementById('parentHeading').classList.remove('panel-heading--disabled');
                            parentCollapse.show();
                        } else {
                            this.hockey.errorMessages = response.data.messages;
                        }
                    });
            }
            , submitParent() {
                let response;
                this.parent.submitting = true;
                response = axios.post('/process/bursaryApplication.php'
                    , {
                        action: 'parent'
                        , timestamp: this.$root.timestamp
                        , relation: this.parent.relation
                        , firstName: this.parent.firstName
                        , lastName: this.parent.lastName
                        , cellPhone: this.parent.cellPhone
                        , email: this.parent.email
                        , address: this.parent.address
                        , city: this.parent.city
                        , country: this.parent.country
                        , region: this.parent.region
                        , postalCode: this.parent.postalCode
                    }
                    , {headers: {'Content-Type': 'application/json'}}
                )
                    .then(response => {
                        this.parent.submitting = false;
                        if (response.data.success === true) {
                            this.parent.errorMessages = [];
                            parentCollapse.hide();
                            document.getElementById('parentSection').classList.remove('in');
                            document.getElementById('questionnaireHeading').classList.remove('panel-heading--disabled');
                            questionnaireCollapse.show();
                        } else {
                            this.parent.errorMessages = response.data.messages;
                        }
                    });
            }
            , submitQuestionnaire() {
                let response;
                let parameters = {
                    action: 'questionnaire'
                    , timestamp: this.$root.timestamp
                    , employmentStatus: this.questionnaire.employmentStatus
                    , income: this.questionnaire.income
                    , playerLivesWith: this.questionnaire.playerLivesWith
                    , otherFunding: this.questionnaire.otherFunding
                    , additionalSummerPrograms: this.questionnaire.additionalSummerPrograms
                };

                if (this.questionnaire.otherFunding === 'Yes') {
                    parameters.otherFunding = this.questionnaire.specifyOtherFunding;
                }
                if (this.questionnaire.additionalSummerPrograms === 'Yes') {
                    parameters.additionalSummerPrograms = this.questionnaire.specifyAdditionalSummerPrograms;
                }

                this.questionnaire.submitting = true;
                response = axios.post('/process/bursaryApplication.php'
                    , parameters
                    , {headers: {'Content-Type': 'application/json'}}
                )
                    .then(response => {
                        this.questionnaire.submitting = false;
                        if (response.data.success === true) {
                            this.questionnaire.errorMessages = [];
                            questionnaireCollapse.hide();
                            document.getElementById('questionnaireSection').classList.remove('in');
                            document.getElementById('playerQuestionnaireHeading').classList.remove('panel-heading--disabled');
                            playerQuestionnaire.show();
                        } else {
                            this.questionnaire.errorMessages = response.data.messages;
                        }
                    });
            }
            , submitPlayerQuestionnaire() {
                let response;
                this.playerQuestionnaire.submitting = true;
                response = axios.post('/process/bursaryApplication.php'
                    , {
                        action: 'playerQuestionnaire'
                        , timestamp: this.$root.timestamp
                        , whyWantAttend: this.playerQuestionnaire.whyWantAttend
                        , hardestMoment: this.playerQuestionnaire.hardestMoment
                        , howOldWhenT1D: this.playerQuestionnaire.howOldWhenT1D
                        , haveFriendsWithT1D: this.playerQuestionnaire.haveFriendsWithT1D
                        , dreamJob: this.playerQuestionnaire.dreamJob
                    }
                    , {headers: {'Content-Type': 'application/json'}}
                )
                    .then(response => {
                        this.playerQuestionnaire.submitting = false;
                        if (response.data.success === true) {
                            this.playerQuestionnaire.errorMessages = [];
                            playerQuestionnaireCollapse.hide();
                            document.getElementById('playerQuestionnaireSection').classList.remove('in');
                            document.getElementById('parentQuestionnaireHeading').classList.remove('panel-heading--disabled');
                            parentQuestionnaireCollapse.show();
                        } else {
                            this.playerQuestionnaire.errorMessages = response.data.messages;
                        }
                    });
            }
            , submitParentQuestionnaire() {
                let response;
                this.parentQuestionnaire.submitting = true;
                response = axios.post('/process/bursaryApplication.php'
                    , {
                        action: 'parentQuestionnaire'
                        , timestamp: this.$root.timestamp
                        , paySmallerFee: this.parentQuestionnaire.paySmallerFee
                        , whyDeservesToAttend: this.parentQuestionnaire.whyDeservesToAttend
                        , eventID: this.parentQuestionnaire.eventID
                    }
                    , {headers: {'Content-Type': 'application/json'}}
                )
                    .then(response => {
                        let parentQuestionnaireIsFine;
                        this.parentQuestionnaire.submitting = false;
                        if (response.data.success === true) {
                            //Update UI
                            this.parentQuestionnaire.errorMessages = [];
                            parentQuestionnaireCollapse.hide();
                            document.getElementById('parentQuestionnaireSection').classList.remove('in');
                            //Now do the actual save
                            parentQuestionnaireIsFine = axios.post('/process/bursaryApplication.php'
                                , {action: 'apply'}
                                , {headers: {'Content-Type': 'application/json'}}
                            )
                        } else {
                            this.parentQuestionnaire.errorMessages = response.data.messages;
                            parentQuestionnaireIsFine = Promise.reject({data: {success: false}});
                        }

                        return parentQuestionnaireIsFine;

                    })
                    .then(response => {
                        if (response.data.success === true) {
                            this.errorMessages = [];
                            this.successMessage = this.$t('editShort.form.success_message');
                            this.parentInformation.submitted = true;
                        } else if (response.data.messages.length > 0) {
                            this.errorMessages = response.data.messages;
                        } else {
                            this.errorMessages.push(this.$t('editShort.form.error_message'));
                        }
                    });
            }
        }
    }
</script>

<style scoped>
    .panel {
        background-color: transparent;
    }

    .item input, .item select, .item textarea {
        margin-top: auto;
    }
</style>

