/*!
 * Drive Marketing Custom Scripts
 *
 * Don't rename this file without updating gulp and webpack.
 */

/*******************************************************
 * If there is a page anchor to go to, go there.
 *
 * This does two things:
 * 1. Make it look all pretty.
 * 2. Account properly for the height offset caused by the navbar. Otherwise the navbar obscures the element.
 */
import {startNativeSmoothScroll as startNativeSmoothScroll} from './components/nativeSmoothScroll';
startNativeSmoothScroll({offset: 200});


/*******************************************************
 *
 * Forms
 * Step one: import form prerequisites
 * Step two: load translations
 * Step three: load all your forms
 *
 *******************************************************/

//******************************************************
//Step one: import form stuff

//Dependancies
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VeeValidate from 'vee-validate';
import VuePikaday from '@enrian/vue-pikaday';

Vue.use(VueI18n);
Vue.use(VuePikaday);
import enValidationMessages from 'vee-validate/dist/locale/en';
import frValidationMessages from 'vee-validate/dist/locale/fr';

//In-house parts
import {default as ScrollToError} from './plugins/scrollToError';
Vue.use(ScrollToError);
import {default as drive} from './components/drive';
import {default as contactForm} from './components/contactForm';
import {default as ambassadorForm} from './components/ambassadorForm';
import {default as eventRegistration} from './components/eventRegistration';
import {default as bursaryApplication} from './components/bursary';
import {default as tournamentRegistration} from './components/tournamentRegistration';

//******************************************************
//Step two: load translations and form data
let i18n, timestamp, hockeyExperience;

Promise.all([
	drive.fetchEdits().then((response) => { i18n = response; })
	, drive.fetchTimestamp().then((response) => { timestamp = response; })
	, drive.fetchHockeyExperience().then((response) => { hockeyExperience = response; })
])
	.then(() => {

		Vue.use(VeeValidate, {
			aria: true,
			classes: true,
			classNames: {
				//valid: 'is-valid',
				invalid: 'error'
			},
			i18n: i18n,
			dictionary: {
				en: enValidationMessages,
				fr: frValidationMessages
			}
		});

		//******************************************************
		//Step three: load all your forms

		new Vue({
			el: '#contactFormContainer'
			, components: {
				'contactForm': contactForm
			}
			, data: {
				timestamp: timestamp
			}
			, i18n: i18n
		});

		let ambassadorFormContainers = document.querySelectorAll('.ambassadorFormContainer');

		ambassadorFormContainers.forEach(function (element) {
			new Vue({
				el: element
				, components: {
					'ambassadorForm': ambassadorForm
				}
				, data: {
					timestamp: timestamp
				}
				, i18n: i18n
			});
		});

		new Vue({
			el: '#eventRegistrationContainer'
			, components: {
				'eventRegistration': eventRegistration
			}
			, data: {
				timestamp: timestamp
				, hockeyExperience: hockeyExperience
			}
			, i18n: i18n
		});

		new Vue({
			el: '#bursaryApplicationContainer'
			, components: {
				'bursaryApplication': bursaryApplication
			}
			, data: {
				timestamp: timestamp
				, hockeyExperience: hockeyExperience
			}
			, i18n: i18n
		});

		new Vue({
			el: '#tournamentRegistrationContainer'
			, components: {
				'tournamentRegistration': tournamentRegistration
			}
			, data: {
				timestamp: timestamp
			}
			, i18n: i18n
		});
	});

/**
 * Add blog pagination once the page loads
 */
import loadMoreArticles from './components/loadMoreArticles';

document.addEventListener('DOMContentLoaded', function () {
	let loadMoreArticlesButton = document.getElementById('more-articles');
	if (loadMoreArticlesButton !== null && typeof loadMoreArticlesButton !== 'undefined') {
		loadMoreArticlesButton.addEventListener('click', function (event) {

			let lastPostID = loadMoreArticlesButton.dataset.lastpostid,
				categoryID = loadMoreArticlesButton.dataset.categoryid,
				languageIndex = loadMoreArticlesButton.dataset.languageindex,
				baseDomain = loadMoreArticlesButton.dataset.basedomain,
				baseUrl = loadMoreArticlesButton.dataset.baseurl;

			//Save HTML contents of button for later
			let loadMoreArticlesButtonLabel = loadMoreArticlesButton.childNodes;

			//Create spinner
			let spinner = document.createElement('i');
			spinner.className = 'fa fa-circle-o-notch fa-spin';

			event.preventDefault();

			//Replace contents of loadMoreArticlesButton with spinner
			while (loadMoreArticlesButton.firstChild) {
				loadMoreArticlesButton.removeChild(loadMoreArticlesButton.firstChild);
			}
			loadMoreArticlesButton.appendChild(spinner);

			loadMoreArticles.loadMoreArticles(lastPostID, categoryID, languageIndex, baseDomain, baseUrl)
				.then(function (response) {
					let posts, newPost, newPostIndex;
					let currentPosts = document.querySelectorAll('.news-list .item');
					//Thi:ken null
					let lastPost = currentPosts.item(currentPosts.length - 1);

					//Turn HTML response into DOM nodes
					let postsHolder = document.createElement('div');
					postsHolder.insertAdjacentHTML('beforeend', response.data.html);
					posts = postsHolder.querySelectorAll('.item ');

					//Avoid button jumping down instead of sliding down with animate.css
					loadMoreArticlesButton.classList.add('animated');
					loadMoreArticlesButton.classList.add('fadeOut');

					for (newPostIndex = 0; newPostIndex < posts.length; newPostIndex++) {
						newPost = posts.item(newPostIndex);

						//Prepare blog post to be animated
						newPost.classList.add('animated');
						newPost.classList.add('fadeInUp');

						//Add post. Animation will happen as soon as it's added
						lastPost.insertAdjacentElement('afterend', newPost);

						console.log(lastPost);
						//Set new placeholder for nest blog post
						lastPost = posts.item(newPostIndex);
						console.log(lastPost);
					}


					// We update the markup for our next AJAX call
					loadMoreArticlesButton.dataset.lastpostid = response.data.lastPostID;
					loadMoreArticlesButton.dataset.categoryid = response.data.categoryID;
					while (loadMoreArticlesButton.firstChild) {
						loadMoreArticlesButton.removeChild(loadMoreArticlesButton.firstChild);
					}
					loadMoreArticlesButtonLabel.forEach(function (element) {
						loadMoreArticlesButton.appendChild(element);
					});

					//If not last page, bring back "load more" button
					if (!response.data.isLastPage) {
						loadMoreArticlesButton.classList.remove('fadeOut');
						loadMoreArticlesButton.classList.add('slow');
						loadMoreArticlesButton.classList.add('fadeInUp');
					}

				})
				.catch(function (error) {
					console.log(error);
				});

		});
	}

});

/**
 * Add video backgrounds
 */
import {startVideoBackgrounds} from './components/videoBackground';

document.addEventListener('DOMContentLoaded', function () {
	startVideoBackgrounds();
});

/**
 * Cookie notice
 */

import Cookies from 'js-cookie';

let bsn = require('bootstrap.native');

const gdpr = document.getElementById('gdpr');
const gdprAlert = new bsn.Alert(gdpr);

if (Cookies.get('closeGdpr') !== 'true') {
	gdpr.classList.add('in');
	gdpr.addEventListener('close.bs.alert', function () {
		Cookies.set('closeGdpr', 'true');
		gdpr.classList.add('hidden');
	});
} else {
	gdpr.classList.add('hidden');
}

