import {isMobile} from '../utilities';

export function startVideoBackgrounds() {
	let videoBackgroundContainers = document.querySelectorAll('.video-background-container');
	//let videoPlaceholders = document.querySelectorAll('.video-background-placeholder');

	if (!isMobile()) {
		videoBackgroundContainers.forEach(function (container) {
			let videoPlaceholder = container.querySelector('.video-background-placeholder');

			let videoElement = document.createElement('video');
			let videoSource = document.createElement('source');
			let fallbackMessage = document.createTextNode('Your browser does not support the video tag.');

			videoElement.classList.add('video-background');
			videoElement.muted = true;
			videoElement.loop = 'loop';

			videoSource.src = container.dataset.videoLink;
			videoSource.type = 'video/mp4';

			videoElement.appendChild(videoSource);
			videoElement.appendChild(fallbackMessage);

			container.style.minHeight = container.dataset.videoHeight;

			container.removeChild(videoPlaceholder);
			container.appendChild(videoElement);

			videoElement.addEventListener('loadedmetadata', function () {
				videoElement.play();
			});
		});
	}
}

