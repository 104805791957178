import axios from 'axios';

export default function getCurrentLanguage() {
	return axios.get('/api/v1/languages/')
		.then(response => {
			let possibleCurrentLanguageIndex = window.location.pathname.split('/')[1];
			let languages, currentLanguageIndex = '', defaultLanguageIndex = '';

			languages = Object.values(response.data.data.languages);
			languages.forEach(language => {
				if (language.index === possibleCurrentLanguageIndex) {
					currentLanguageIndex = language.index;
				}
				if (language.default === 'Y') {
					defaultLanguageIndex = language.index;
				}
			});

			if (currentLanguageIndex.length === 0) {
				currentLanguageIndex = defaultLanguageIndex;
			}
			return Promise.resolve(currentLanguageIndex);
		});
}
