<template>
  <div>
    <div id="accordion" class="panel-group leave-comment-wrap pb-0" role="tablist" aria-multiselectable="true">
      <div class="panel panel-default">
        <div class="panel-heading" role="tab" id="playerInformationHeading">
          <h2 class="panel-title">
            <a class="collapsed" role="button" href="#playerInformationSection" aria-expanded="false"
               aria-controls="playerInformationSection">
              {{ $t('editShort.form.title_player_information') }}
            </a>
          </h2>
        </div>
        <div id="playerInformationSection" class="panel-collapse collapse in" role="tabpanel"
             aria-labelledby="playerInformationHeading">
          <div class="panel-body">
            <form action="#" method="post" @submit.prevent="validateBeforeSubmit('playerInformation')"
                  data-vv-scope="playerInformation">
              <div class="row flex flex-wrap">
                <div class="col-xs-12 col-md-4 item">
                  <label for="playerInformationFirstName">
                    {{ $t('editShort.form.label_first_name') }}
                  </label>
                  <input type="text" id="playerInformationFirstName" name="firstName"
                         v-validate="'required'"
                         v-model="playerInformation.firstName" autocomplete="fname"
                         :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerInformation.firstName') }}
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 item">
                  <label for="playerInformationMiddleName">
                    {{ $t('editShort.form.label_middle_name') }}</label>
                  <input type="text" id="playerInformationMiddleName"
                         name="middleName"
                         v-model="playerInformation.middleName" autocomplete="mname">
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="playerInformationLastName">
                    {{ $t('editShort.form.label_last_name') }}</label>
                  <input type="text" id="playerInformationLastName" name="lastName"
                         v-validate="'required'"
                         v-model="playerInformation.lastName" autocomplete="lname"
                         :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerInformation.lastName') }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-8 item">
                  <label for="playerInformationAddress">{{ $t('editShort.form.label_address') }}</label>
                  <input type="text" id="playerInformationAddress" name="address" v-validate="'required'"
                         v-model="playerInformation.address" autocomplete="street-address"
                         :data-vv-as="String($t('editShort.form.label_address')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerInformation.address') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="playerInformationCountry">{{ $t('editShort.form.label_country') }}</label>
                  <select id="playerInformationCountry" name="country"
                          autocomplete="country"
                          v-on:change="countryOnChange('playerInformation')"
                          v-model="playerInformation.country"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_country')).toLowerCase()">
                    <option v-for="country in countries" v-bind:value="country.ISOCode">
                      {{ country[$validator.locale].mainTitle }}
                    </option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('playerInformation.country') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="playerInformationCity">{{ $t('editShort.form.label_city') }}</label>
                  <input type="text" id="playerInformationCity" name="city" v-validate="'required'"
                         v-model="playerInformation.city" autocomplete="address-level2"
                         :data-vv-as="String($t('editShort.form.label_city')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerInformation.city') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="playerInformationRegion">{{ $t('editShort.form.label_state_province') }}</label>
                  <select id="playerInformationRegion" name="region" v-model="playerInformation.region"
                          autocomplete="address-level1"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_state_province')).toLowerCase()">
                    <option v-for="region in playerInformation.regions"
                            :value="region.ISOCode">
                      {{ region[$validator.locale].mainTitle }}
                    </option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('playerInformation.region') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="playerInformationPostalCode">{{ $t('editShort.form.label_postal_code') }}</label>
                  <input type="text" id="playerInformationPostalCode" name="postalCode"
                         v-validate="'required'"
                         v-model="playerInformation.postalCode" autocomplete="postal-code"
                         :data-vv-as="String($t('editShort.form.label_postal_code')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerInformation.postalCode') }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 item">
                  <label for="playerInformationPhone">{{ $t('editShort.form.label_phone') }}</label>
                  <input type="text" id="playerInformationPhone" name="phone" v-validate="'required'"
                         v-model="playerInformation.phone" autocomplete="tel"
                         :data-vv-as="String($t('editShort.form.label_phone')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerInformation.phone') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 item">
                  <label for="playerInformationEmail">{{ $t('editShort.form.label_email') }}</label>
                  <input type="email" id="playerInformationEmail" name="email" v-validate="'required'"
                         v-model="playerInformation.email" autocomplete="email"
                         :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerInformation.email') }}
                  </div>
                </div>


              </div>

              <div class="row">
                <div class="col-xs-12">
                  <div v-if="playerInformation.errorMessages.length > 0"
                       class="alert alert-danger animated fadeInDown">
                    <ul v-if="playerInformation.errorMessages.length > 1" class="mt-0">
                      <li class="w-100 text-left font-weight-normal"
                          v-for="message in playerInformation.errorMessages">
                        {{ message }}
                      </li>
                    </ul>
                    <span v-else>{{ playerInformation.errorMessages[0] }}</span>
                  </div>
                </div>
                <div class="col-xs-12 text-right">
                  <button class="comment-submit text-white" :disabled="playerInformation.submitting === true">
                    <span v-if="playerInformation.submitting === false">
                      {{ $t('editShort.form.label_next') }}
                    </span>
                    <i class="fa fa-spinner fa-spin"
                       v-if="playerInformation.submitting === true"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-heading panel-heading--disabled" role="tab" id="playerProfileHeading">
          <h2 class="panel-title">
            <a role="button" href="#playerProfileSection" aria-expanded="true"
               aria-controls="playerProfileSection">
              {{ $t('editShort.form.title_player_profile') }}
            </a>
          </h2>
        </div>
        <div id="playerProfileSection" class="panel-collapse collapse" role="tabpanel"
             aria-labelledby="playerProfileHeading">
          <div class="panel-body">
            <form action="#" method="post" @submit.prevent="validateBeforeSubmit('playerProfile')"
                  data-vv-scope="playerProfile">
              <div class="row flex flex-wrap">
                <div class="col-xs-12 col-sm-2 item">
                  <fieldset>
                    <legend>{{ $t('editShort.form.label_gender') }}</legend>
                    <label class="item float-left mr-8">
                      <input type="radio" name="gender"
                             v-model="playerProfile.gender"
                             value="M" v-validate="'required'"
                             :data-vv-as="String($t('editShort.form.label_gender')).toLowerCase()">
                      <span class="name">{{ $t('editShort.form.label_male') }}</span>
                    </label>
                    <label class="item float-left">
                      <input type="radio" name="gender"
                             v-model="playerProfile.gender"
                             value="F">
                      <span class="name">{{ $t('editShort.form.label_female') }}</span>
                    </label>
                  </fieldset>
                  <div class="text-red">
                    {{ errors.first('playerProfile.gender') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="playerProfileAge">{{ $t('editShort.form.label_age') }}</label>
                  <input type="number" id="playerProfileAge" name="age"
                         v-validate="'required'"
                         v-model="playerProfile.age"
                         :data-vv-as="String($t('editShort.form.label_age')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerProfile.age') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 item">
                  <label for="playerProfileDateOfBirth">
                    {{ $t('editShort.form.label_dateOfBirth') }}</label>
                  <input type="text" id="playerProfileDateOfBirth" name="dateOfBirth"
                         autocomplete="anystringbutthatstring"
                         class="datepicker" v-validate="'required'"
                         data-vv-validate-on="change|select|blur"
                         v-model="playerProfile.dateOfBirth"
                         :data-vv-as="String($t('editShort.form.label_dateOfBirth')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerProfile.dateOfBirth') }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6">
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 item">
                      <label for="playerProfileHeight">{{ $t('editShort.form.label_height') }}</label>
                      <input type="text" id="playerProfileHeight" name="height"
                             v-validate="'required'"
                             v-model="playerProfile.height"
                             :data-vv-as="String($t('editShort.form.label_height')).toLowerCase()">
                      <div class="text-red">
                        {{ errors.first('playerProfile.height') }}
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 item">
                      <label for="playerProfileWeight">
                        {{ $t('editShort.form.label_weight') }}</label>
                      <input type="text" id="playerProfileWeight" name="weight"
                             v-validate="'required'"
                             v-model="playerProfile.weight"
                             :data-vv-as="String($t('editShort.form.label_weight')).toLowerCase()">
                      <div class="text-red">
                        {{ errors.first('playerProfile.weight') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-2 item">
                  <fieldset>
                    <legend>{{ $t('editShort.form.label_shootLeftRight') }}</legend>
                    <label class="item float-left mr-8">
                      <input type="radio" name="shootLeftRight" value="L"
                             v-model="playerProfile.shootLeftRight"
                             v-validate="'required'"
                             :data-vv-as="String($t('editShort.form.label_shootLeftRight')).toLowerCase()">
                      <span class="name">{{ $t('editShort.form.label_left') }}</span>
                    </label>
                    <label class="item float-left">
                      <input type="radio" name="shootLeftRight" value="R"
                             v-model="playerProfile.shootLeftRight">
                      <span class="name">{{ $t('editShort.form.label_right') }}</span>
                    </label>
                  </fieldset>
                  <div class="text-red">
                    {{ errors.first('playerProfile.shootLeftRight') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">

                  <label for="playerProfilePosition">{{ $t('editShort.form.label_position') }}</label>
                  <select name="position" id="playerProfilePosition"
                          v-model="playerProfile.position"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_position')).toLowerCase()">
                    <option value="tournamentRegistration-positions-goaltender">
                      {{ $t('tournamentRegistration.positions.goaltender') }}
                    </option>
                    <option value="tournamentRegistration-positions-leftDefense">
                      {{ $t('tournamentRegistration.positions.leftDefense') }}
                    </option>
                    <option value="tournamentRegistration-positions-rightDefense">
                      {{ $t('tournamentRegistration.positions.rightDefense') }}
                    </option>
                    <option value="tournamentRegistration-positions-center">
                      {{ $t('tournamentRegistration.positions.center') }}
                    </option>
                    <option value="tournamentRegistration-positions-leftWing">
                      {{ $t('tournamentRegistration.positions.leftWing') }}
                    </option>
                    <option value="tournamentRegistration-positions-rightWing">
                      {{ $t('tournamentRegistration.positions.rightWing') }}
                    </option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('playerProfile.position') }}
                  </div>
                </div>

                <div class="col-xs-12">
                  <h3 class="text-3xl py-0">{{ $t('editShort.form.label_hockeyExperience') }}</h3>
                </div>
                <div class="col-sm-4 item">
                  <label for="playerProfileHockeyExperience">
                    {{ $t('editShort.form.label_howManyYearsHaveYouPlayed') }}
                  </label>
                  <input type="number" id="playerProfileHockeyExperience" name="hockeyExperience"
                         v-model="playerProfile.yearsPlayed"
                         v-validate="'required'"
                         :data-vv-as="String($t('editShort.form.label_hockeyExperience')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerProfile.yearsPlayed') }}
                  </div>
                </div>
                <div class="col-sm-3 item">
                  <fieldset>
                    <legend>{{ $t('editShort.form.label_attendedDskateBefore') }}</legend>
                    <div class="row">
                      <div class="col-xs-6">
                        <label>
                          <input type="radio" name="attendedDskateBefore" value="1"
                                 v-model="playerProfile.attendedDskateBefore"
                                 v-validate="'required'"
                                 :data-vv-as="String($t('editShort.form.label_attendedDskateBefore')).toLowerCase()">
                          <span class="name">{{ $t('editShort.form.label_yes') }}</span>
                        </label>
                      </div>
                      <div class="col-xs-6">
                        <label>
                          <input type="radio" name="position" value="0"
                                 v-model="playerProfile.attendedDskateBefore">
                          <span class="name">{{ $t('editShort.form.label_no') }}</span>
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  <div class="text-red">
                    {{ errors.first('playerProfile.attendedDskateBefore') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-5 item">
                  <fieldset>
                    <legend>{{ $t('editShort.form.label_currentHockeyTier') }}</legend>
                    <div class="row">
                      <div class="col-sm-6">
                        <label>
                          <input type="radio" name="currentHockeyTier" value="tournamentRegistration-hockeyTiers-atom"
                                 v-model="playerProfile.currentHockeyTier"
                                 v-validate="'required'"
                                 :data-vv-as="String($t('editShort.form.label_currentHockeyTier')).toLowerCase()">
                          <span class="name">{{ $t('tournamentRegistration.hockeyTiers.atom') }}</span>
                        </label>
                      </div>
                      <div class="col-sm-6">
                        <label>
                          <input type="radio" name="currentHockeyTier"
                                 value="tournamentRegistration-hockeyTiers-peewee"
                                 v-model="playerProfile.currentHockeyTier">
                          <span class="name">{{ $t('tournamentRegistration.hockeyTiers.peewee') }}</span>
                        </label>
                      </div>
                      <div class="col-sm-6">
                        <label>
                          <input type="radio"
                                 name="currentHockeyTier"
                                 value="tournamentRegistration-hockeyTiers-bantam"
                                 v-model="playerProfile.currentHockeyTier">
                          <span class="name">{{ $t('tournamentRegistration.hockeyTiers.bantam') }}</span>
                        </label>
                      </div>
                      <div class="col-sm-6">
                        <label>
                          <input type="radio"
                                 name="currentHockeyTier"
                                 value="tournamentRegistration-hockeyTiers-midget"
                                 v-model="playerProfile.currentHockeyTier">
                          <span class="name">{{ $t('tournamentRegistration.hockeyTiers.midget') }}</span>
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  <div class="text-red">
                    {{ errors.first('playerProfile.currentHockeyTier') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="playerProfileCurrentHockeyLevel">
                    {{ $t('editShort.form.label_currentHockeyLevel') }}
                  </label>
                  <select name="currentHockeyLevel" id="playerProfileCurrentHockeyLevel"
                          v-model="playerProfile.currentHockeyLevel"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_currentHockeyLevel')).toLowerCase()">
                    <option value="AAA">AAA</option>
                    <option value="AA">AA</option>
                    <option value="A">A</option>
                    <option value="BB">BB</option>
                    <option value="CC">CC</option>
                    <option value="B">B</option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('playerProfile.currentHockeyLevel') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-8 item">
                  <fieldset>
                    <legend>{{ $t('editShort.form.label_playerPlays') }}</legend>
                    <div class="row">
                      <div class="col-sm-4">
                        <label>
                          <input type="radio" name="playerPlays" value="tournamentRegistration-playerPlays-powerPlay"
                                 v-model="playerProfile.playerPlays"
                                 v-validate="'required'"
                                 :data-vv-as="String($t('editShort.form.label_playerPlays')).toLowerCase()">
                          <span class="name">{{$t('tournamentRegistration.playerPlays.powerPlay')}}</span>
                        </label>
                      </div>
                      <div class="col-sm-4">
                        <label>
                          <input type="radio" name="playerPlays" value="tournamentRegistration-playerPlays-penaltyKill"
                                 v-model="playerProfile.playerPlays">
                          <span class="name">{{$t('tournamentRegistration.playerPlays.penaltyKill')}}</span>
                        </label>
                      </div>
                      <div class="col-sm-4">
                        <label>
                          <input type="radio" name="playerPlays"
                                 value="tournamentRegistration-playerPlays-noSpecialTeams"
                                 v-model="playerProfile.playerPlays">
                          <span class="name">{{$t('tournamentRegistration.playerPlays.noSpecialTeams')}}</span>
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  <div class="text-red">
                    {{ errors.first('playerProfile.playerPlays') }}
                  </div>
                </div>
                <div class="col-xs-12">
                  <h3 class="text-3xl py-0">{{$t('editShort.form.label_20182019stats')}}<!--2018-19 Stats--></h3>
                </div>
                <div class="col-xs-12 col-sm-8 item">
                  <div class="row">
                    <div class="col-sm-6">
                      <label class="w-full"
                             for="playerProfileGamesPlayed">{{$t('editShort.form.label_gamesPlayed')}}</label>
                      <input type="number" id="playerProfileGamesPlayed" name="gamesPlayed" class="w-full"
                             v-validate="'required'"
                             v-model="playerProfile.gamesPlayed"
                             :data-vv-as="String($t('editShort.form.label_gamesPlayed')).toLowerCase()">
                    </div>
                    <div class="col-sm-6">
                      <label class="w-full" for="playerProfileGoals">{{$t('editShort.form.label_goals')}}</label>
                      <input type="number" id="playerProfileGoals" name="goals" class="w-full"
                             v-validate="'required'"
                             v-model="playerProfile.goals"
                             :data-vv-as="String($t('editShort.form.label_goals')).toLowerCase()">
                    </div>
                    <div class="col-sm-6">
                      <label class="w-full" for="playerProfileAssists">{{$t('editShort.form.label_assists')}}</label>
                      <input type="number" id="playerProfileAssists" name="assists" class="w-full"
                             v-validate="'required'"
                             v-model="playerProfile.assists"
                             :data-vv-as="String($t('editShort.form.label_assists')).toLowerCase()">
                    </div>
                    <div class="col-sm-6">
                      <label class="w-full"
                             for="playerProfilePenaltyMinutes">{{$t('editShort.form.label_penaltyMinutes')}}</label>
                      <input type="number" id="playerProfilePenaltyMinutes" name="penaltyMinutes" class="w-full"
                             v-validate="'required'"
                             v-model="playerProfile.penaltyMinutes"
                             :data-vv-as="String($t('editShort.form.label_penaltyMinutes')).toLowerCase()">
                    </div>
                  </div>

                  <div class="text-red">
                    {{ errors.first('playerProfile.playerPlays') }}
                    {{ errors.first('playerProfile.goals') }}
                    {{ errors.first('playerProfile.assists') }}
                    {{ errors.first('playerProfile.penaltyMinutes') }}
                  </div>

                </div>

                <div class="col-xs-12 col-sm-4 item">
                  <label for="playerProfileLinePlayed">{{ $t('editShort.form.label_linePlayed') }}</label>
                  <select name="linePlayed" id="playerProfileLinePlayed"
                          v-model="playerProfile.linePlayed"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_linePlayed')).toLowerCase()">
                    <option value="tournamentRegistration-linePlayed-firstLine">
                      {{$t('tournamentRegistration.linePlayed.firstLine')}}
                    </option>
                    <option value="tournamentRegistration-linePlayed-secondLine">
                      {{$t('tournamentRegistration.linePlayed.secondLine')}}
                    </option>
                    <option value="tournamentRegistration-linePlayed-thirdLine">
                      {{$t('tournamentRegistration.linePlayed.thirdLine')}}
                    </option>
                    <option value="tournamentRegistration-linePlayed-firstPair">
                      {{$t('tournamentRegistration.linePlayed.firstPair')}}
                    </option>
                    <option value="tournamentRegistration-linePlayed-secondPair">
                      {{$t('tournamentRegistration.linePlayed.secondPair')}}
                    </option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('playerProfile.linePlayed') }}
                  </div>
                </div>

                <div class="col-xs-12 col-md-6 col-lg-4 item">
                  <label for="playerProfilePlayerRequest">
                    {{ $t('editShort.form.label_playerRequest') }}</label>
                  <input type="text" id="playerProfilePlayerRequest" name="playerRequest"
                         v-model="playerProfile.playerRequest">
                </div>

                <div class="col-xs-12 col-md-6 col-lg-8 item">
                  <label for="playerProfileOtherExperience">
                    {{ $t('editShort.form.label_doYouHaveOtherExperience') }}</label>
                  <input type="text" id="playerProfileOtherExperience"
                         name="otherExperience"
                         v-model="playerProfile.otherExperience">
                </div>
              </div>
              <div class="row">
                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                <div class="col-xs-12">
                  <div v-if="playerProfile.errorMessages.length > 0"
                       class="alert alert-danger animated fadeInDown">
                    <ul v-if="playerProfile.errorMessages.length > 1" class="mt-0">
                      <li class="w-100 text-left font-weight-normal"
                          v-for="message in playerProfile.errorMessages">
                        {{ message }}
                      </li>
                    </ul>
                    <span v-else>{{ playerProfile.errorMessages[0] }}</span>
                  </div>
                </div>
                <div class="col-xs-12 text-right">
                  <button class="comment-submit text-white" :disabled="playerProfile.submitting === true">
                    <span v-if="playerProfile.submitting === false">
                      {{ $t('editShort.form.label_next') }}</span>
                    <i class="fa fa-spinner fa-spin"
                       v-if="playerProfile.submitting === true"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-heading panel-heading--disabled" role="tab" id="parentsHeading">
          <h2 class="panel-title">
            <a class="collapsed" role="button" href="#parentsSection" aria-expanded="false"
               aria-controls="parentsSection">
              {{ $t('editShort.form.title_parent_information') }}
            </a>
          </h2>
        </div>
        <div id="parentsSection" class="panel-collapse collapse" role="tabpanel"
             aria-labelledby="parentsHeading">
          <div class="panel-body">
            <form action="#" method="post" @submit.prevent="validateBeforeSubmit('parents')"
                  data-vv-scope="parents">

              <div v-for="(parent, index) in parents.parents" :key="index">
                <div class="row mt-4 mb-10" v-if="index !== 0">
                  <div class="col-xs-12">
                    <hr>
                    <button class="close mr-6" v-on:click="removeParent(index)"
                            :aria-label="$t('editShort.form.label_remove_parent')" type="button">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="row flex flex-wrap items-center">
                  <div class="col-xs-12 col-sm-4 item">
                    <label :for="'parentRelation' + index" class="font-weight-normal">
                      {{ $t('editShort.form.label_relation') }}</label>
                    <select :id="'parentRelation' + index" :name="'relation' + index"
                            v-model="parents.parents[index].relation"
                            v-validate="'required'"
                            :data-vv-as="String($t('editShort.form.label_relation')).toLowerCase()">
                      <option value="Father">
                        {{ $t('editShort.form.label_father') }}
                      </option>
                      <option value="Mother">
                        {{ $t('editShort.form.label_mother') }}
                      </option>
                      <option value="Guardian">
                        {{ $t('editShort.form.label_guardian') }}
                      </option>
                    </select>
                    <div class="text-red">
                      {{ getErrorMessageFor('relation' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-4 ml-auto text-right">
                    <button v-on:click="addParent()" class="comment-submit text-white"
                            type="button">
                      {{ $t('editShort.form.label_add_parent') }}
                    </button>
                  </div>
                </div>

                <div class="row flex flex-wrap">

                  <div class="col-xs-12 col-sm-4 item">
                    <label :for="'parentFirstName' + index">{{ $t('editShort.form.label_first_name')
                      }}</label>
                    <input type="text" :id="'parentFirstName' + index"
                           :name="'firstName' + index"
                           v-validate="'required'"
                           v-model="parents.parents[index].firstName" autocomplete="fname"
                           :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                    <div class="text-red">
                      {{ getErrorMessageFor('firstName' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-4 item">
                    <label :for="'parentLastName' + index">{{ $t('editShort.form.label_last_name')
                      }}</label>
                    <input type="text" :id="'parentLastName' + index"
                           :name="'lastName' + index"
                           v-validate="'required'"
                           v-model="parents.parents[index].lastName" autocomplete="lname"
                           :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                    <div class="text-red">
                      {{ getErrorMessageFor('lastName' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-4 item">
                    <label :for="'parentEmail' + index">
                      {{ $t('editShort.form.label_email') }}</label>
                    <input type="email" :id="'parentEmail' + index" :name="'email' + index"
                           v-validate="'required'"
                           v-model="parents.parents[index].email" autocomplete="email"
                           :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                    <div class="text-red">
                      {{ getErrorMessageFor('email' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-8 item">
                    <label :for="'parentAddress' + index">
                      {{ $t('editShort.form.label_address') }}
                      <!--({{ $t('editShort.form.label_if_different_from_player') }})-->
                    </label>
                    <input type="text" :id="'parentAddress' + index" :name="'address' + index"
                           v-validate="'required'"
                           v-model="parents.parents[index].address"
                           autocomplete="street-address"
                           :data-vv-as="String($t('editShort.form.label_address')).toLowerCase()">
                    <div class="text-red">
                      {{ getErrorMessageFor('address' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-4 item">
                    <label :for="'parentCountry' + index">
                      {{ $t('editShort.form.label_country') }}</label>
                    <select :id="'parentCountry' + index" :name="'country' + index"
                            autocomplete="country"
                            v-on:change="parentCountryOnChange(index)"
                            v-model="parents.parents[index].country"
                            v-validate="'required'"
                            :data-vv-as="String($t('editShort.form.label_country')).toLowerCase()">
                      <option v-for="country in countries" v-bind:value="country.ISOCode">
                        {{ country[$validator.locale].mainTitle }}
                      </option>
                    </select>
                    <div class="text-red">
                      {{ getErrorMessageFor('country' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-4 item">
                    <label :for="'parentCity' + index">{{ $t('editShort.form.label_city') }}</label>
                    <input type="text" :id="'parentCity' + index" :name="'city' + index"
                           v-validate="'required'"
                           v-model="parents.parents[index].city"
                           autocomplete="address-level2"
                           :data-vv-as="String($t('editShort.form.label_city')).toLowerCase()">
                    <div class="text-red">
                      {{ getErrorMessageFor('city' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-4 item">
                    <label :for="'parentRegion' + index">
                      {{ $t('editShort.form.label_state_province') }}</label>
                    <select :id="'parentRegion' + index" :name="'region' + index"
                            v-model="parents.parents[index].region"
                            autocomplete="address-level1"
                            v-validate="'required'"
                            :data-vv-as="String($t('editShort.form.label_state_province')).toLowerCase()">
                      <option v-for="region in parents.parents[index].regions"
                              :value="region.ISOCode">
                        {{ region[$validator.locale].mainTitle }}
                      </option>
                    </select>
                    <div class="text-red">
                      {{ getErrorMessageFor('region' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-4 item">
                    <label :for="'parentPostalCode' + index">
                      {{$t('editShort.form.label_postal_code')}}</label>
                    <input type="text" :id="'parentPostalCode' + index"
                           :name="'postalCode' + index"
                           v-validate="'required'"
                           v-model="parents.parents[index].postalCode"
                           autocomplete="postal-code"
                           :data-vv-as="String($t('editShort.form.label_postal_code')).toLowerCase()">
                    <div class="text-red">
                      {{ getErrorMessageFor('postalCode' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 item">
                    <label :for="'parentCellPhone' + index">
                      {{$t('editShort.form.label_business_cell_phone')}}</label>
                    <input type="text" :id="'parentCellPhone' + index"
                           placeholder="*** *** ****"
                           :name="'cellPhone' + index"
                           v-validate="'required'"
                           v-model="parents.parents[index].cellPhone" autocomplete="tel"
                           :data-vv-as="String($t('editShort.form.label_business_cell_phone')).toLowerCase()">
                    <div class="text-red">
                      {{ getErrorMessageFor('cellPhone' + index) }}
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 item">
                    <label :for="'parentHomePhone' + index">
                      {{ $t('editShort.form.label_home_phone') }}</label>
                    <input type="text" :id="'parentHomePhone' + index"
                           :name="'homePhone' + index"
                           placeholder="*** *** ****"
                           v-validate="'required'"
                           v-model="parents.parents[index].homePhone" autocomplete="tel"
                           :data-vv-as="String($t('editShort.form.label_home_phone')).toLowerCase()">
                    <div class="text-red">
                      {{ getErrorMessageFor('homePhone' + index) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row flex flex-wrap">
                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                <div class="col-xs-12">
                  <div v-if="parents.errorMessages.length > 0"
                       class="alert alert-danger animated fadeInDown">
                    <ul v-if="parents.errorMessages.length > 1" class="mt-0">
                      <li class="w-100 text-left font-weight-normal"
                          v-for="message in parents.errorMessages">
                        {{ message }}
                      </li>
                    </ul>
                    <span v-else>{{ parents.errorMessages[0] }}</span>
                  </div>
                </div>
                <div class="col-xs-12 text-right">
                  <button class="comment-submit text-white"
                          :disabled="parents.submitting === true">
                                        <span
                                          v-if="parents.submitting === false">{{ $t('editShort.form.label_next') }}</span>
                    <i class="fa fa-spinner fa-spin"
                       v-if="parents.submitting === true"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!--
      <div class="panel panel-default">
        <div class="panel-heading panel-heading--disabled" role="tab" id="contactHeading">
          <h2 class="panel-title">
            <a role="button" href="#contactSection" aria-expanded="true" aria-controls="contactSection">
              {{ $t('editShort.form.title_contact_information') }}
            </a>
          </h2>
        </div>
        <div id="contactSection" class="panel-collapse collapse" role="tabpanel"
             aria-labelledby="contactHeading">
          <div class="panel-body">
            <form action="#" method="post" @submit.prevent="validateBeforeSubmit('contact')" data-vv-scope="contact">
              <div class="row flex flex-wrap">
                <div class="col-xs-12 col-sm-5 item">
                  <label for="contactFirstName">{{ $t('editShort.form.label_first_name') }}</label>
                  <input type="text" id="contactFirstName" name="firstName" v-validate="'required'"
                         v-model="contact.firstName" autocomplete="fname"
                         :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.firstName') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-5 item">
                  <label for="contactLastName">{{ $t('editShort.form.label_last_name') }}</label>
                  <input type="text" id="contactLastName" name="lastName" v-validate="'required'"
                         v-model="contact.lastName" autocomplete="lname"
                         :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.lastName') }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-2 item">
                  <fieldset>
                    <legend>{{ $t('editShort.form.label_gender') }}</legend>
                    <label class="item float-left mr-8">
                      <input type="radio" name="gender"
                             v-model="contact.gender"
                             value="M" v-validate="'required'"
                             :data-vv-as="String($t('editShort.form.label_gender')).toLowerCase()">
                      <span class="name">{{ $t('editShort.form.label_male') }}</span>
                    </label>
                    <label class="item float-left">
                      <input type="radio" name="gender"
                             v-model="contact.gender"
                             value="F">
                      <span class="name">{{ $t('editShort.form.label_female') }}</span>
                    </label>
                  </fieldset>
                  <div class="text-red">
                    {{ errors.first('contact.gender') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="contactDateOfBirth">
                    {{ $t('editShort.form.label_dateOfBirth') }}</label>
                  <input type="text" id="contactDateOfBirth" name="dateOfBirth"
                         autocomplete="anystringbutthatstring"
                         class="datepicker" v-validate="'required'"
                         data-vv-validate-on="change|select|blur"
                         v-model="contact.dateOfBirth"
                         :data-vv-as="String($t('editShort.form.label_dateOfBirth')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.dateOfBirth') }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-4 item">
                  <label for="contactPhone">{{ $t('editShort.form.label_phone') }}</label>
                  <input type="text" id="contactPhone" name="phone" v-validate="'required'"
                         placeholder="*** *** ****"
                         v-model="contact.phone" autocomplete="tel"
                         :data-vv-as="String($t('editShort.form.label_phone')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.phone') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="contactEmail">{{ $t('editShort.form.label_email') }}</label>
                  <input type="email" id="contactEmail" name="email" v-validate="'required'"
                         v-model="contact.email" autocomplete="email"
                         :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.email') }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-8 item">
                  <label for="contactAddress">{{ $t('editShort.form.label_address') }}</label>
                  <input type="text" id="contactAddress" name="address" v-validate="'required'"
                         v-model="contact.address" autocomplete="street-address"
                         :data-vv-as="String($t('editShort.form.label_address')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.address') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="contactCountry">{{ $t('editShort.form.label_country') }}</label>
                  <select id="contactCountry" name="country"
                          autocomplete="country"
                          v-on:change="countryOnChange('contact')"
                          v-model="contact.country"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_country')).toLowerCase()">
                    <option v-for="country in countries" v-bind:value="country.ISOCode">
                      {{ country[$validator.locale].mainTitle }}
                    </option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('contact.country') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="contactCity">{{ $t('editShort.form.label_city') }}</label>
                  <input type="text" id="contactCity" name="city" v-validate="'required'"
                         v-model="contact.city" autocomplete="address-level2"
                         :data-vv-as="String($t('editShort.form.label_city')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.city') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="contactRegion">{{ $t('editShort.form.label_state_province') }}</label>
                  <select id="contactRegion" name="region" v-model="contact.region"
                          autocomplete="address-level1"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_state_province')).toLowerCase()">
                    <option v-for="region in contact.regions"
                            :value="region.ISOCode">
                      {{ region[$validator.locale].mainTitle }}
                    </option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('contact.region') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="contactPostalCode">{{ $t('editShort.form.label_postal_code') }}</label>
                  <input type="text" id="contactPostalCode" name="postalCode"
                         v-validate="'required'"
                         v-model="contact.postalCode" autocomplete="postal-code"
                         :data-vv-as="String($t('editShort.form.label_postal_code')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.postalCode') }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-8 item">
                  <label for="contactHealthCardNumber">{{ $t('editShort.form.label_healthcard') }}</label>
                  <input type="text" id="contactHealthcardNumber" name="healthcard" v-validate="'required'"
                         v-model="contact.healthcardNumber"
                         :data-vv-as="String($t('editShort.form.label_healthcardNumber')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('contact.healthcardNumber') }}
                  </div>
                </div>

                <div class="col-xs-12 col-sm-4 item">
                  <label for="contactExpiryDate">
                    {{ $t('editShort.form.label_expiryDate') }}</label>
                  <input type="text" id="contactExpiryDate" name="expiryDate"
                         autocomplete="anystringbutthatstring"
                         class="datepicker" v-validate="'required'"
                         data-vv-validate-on="change|select|blur"
                         v-model="contact.expiryDate"
                         :data-vv-as="String($t('editShort.form.label_expiryDate')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('playerProfile.expiryDate') }}
                  </div>
                </div>

                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                <div class="col-xs-12">
                  <div v-if="contact.errorMessages.length > 0"
                       class="alert alert-danger animated fadeInDown">
                    <ul v-if="contact.errorMessages.length > 1" class="mt-0">
                      <li class="w-100 text-left font-weight-normal"
                          v-for="message in contact.errorMessages">
                        {{ message }}
                      </li>
                    </ul>
                    <span v-else>{{ contact.errorMessages[0] }}</span>
                  </div>
                </div>
                <div class="col-xs-12 text-right">
                  <button class="comment-submit text-white" :disabled="contact.submitting === true">
                    <span v-if="contact.submitting === false">{{ $t('editShort.form.label_next') }}</span>
                    <i class="fa fa-spinner fa-spin" v-if="contact.submitting === true"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-heading panel-heading--disabled" role="tab" id="emergencyContactHeading">
          <h2 class="panel-title">
            <a role="button" href="#emergencyContactSection" aria-expanded="true"
               aria-controls="emergencyContactSection">
              {{ $t('editShort.form.title_emergency_contact') }}
            </a>
          </h2>
        </div>
        <div id="emergencyContactSection" class="panel-collapse collapse" role="tabpanel"
             aria-labelledby="emergencyContactHeading">
          <div class="panel-body">
            <form action="#" method="post" @submit.prevent="validateBeforeSubmit('emergencyContact')"
                  data-vv-scope="emergencyContact">
              <div class="row flex flex-wrap">

                <div class="col-xs-12 col-sm-4 item">
                  <label for="emergencyContactFirstName">{{ $t('editShort.form.label_first_name') }}</label>
                  <input type="text" id="emergencyContactFirstName" name="firstName" v-validate="'required'"
                         v-model="emergencyContact.firstName" autocomplete="fname"
                         :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('emergencyContact.firstName') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="emergencyContactLastName">{{ $t('editShort.form.label_last_name') }}</label>
                  <input type="text" id="emergencyContactLastName" name="lastName" v-validate="'required'"
                         v-model="emergencyContact.lastName" autocomplete="lname"
                         :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('emergencyContact.lastName') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="emergencyContactRelation">{{ $t('editShort.form.label_relation') }}</label>
                  <input type="text" id="emergencyContactRelation" name="relation" v-validate="'required'"
                         v-model="emergencyContact.relation"
                         :data-vv-as="String($t('editShort.form.label_relation')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('emergencyContact.relation') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-8 item">
                  <label for="contactAddress">{{ $t('editShort.form.label_address') }}</label>
                  <input type="text" id="emergencyContactAddress" name="address" v-validate="'required'"
                         v-model="emergencyContact.address" autocomplete="street-address"
                         :data-vv-as="String($t('editShort.form.label_address')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('emergencyContact.address') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="emergencyContactCountry">{{ $t('editShort.form.label_country') }}</label>
                  <select id="emergencyContactCountry" name="country"
                          autocomplete="country"
                          v-on:change="countryOnChange('emergencyContact')"
                          v-model="emergencyContact.country"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_country')).toLowerCase()">
                    <option v-for="country in countries" v-bind:value="country.ISOCode">
                      {{ country[$validator.locale].mainTitle }}
                    </option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('emergencyContact.country') }}
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 item">
                  <label for="emergencyContactCity">{{ $t('editShort.form.label_city') }}</label>
                  <input type="text" id="emergencyContactCity" name="city" v-validate="'required'"
                         v-model="emergencyContact.city" autocomplete="address-level2"
                         :data-vv-as="String($t('editShort.form.label_city')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('emergencyContact.city') }}
                  </div>
                </div>
                <div class="col-xs-12 col-sm-4 item">
                  <label for="emergencyContactRegion">{{ $t('editShort.form.label_state_province') }}</label>
                  <select id="emergencyContactRegion" name="region" v-model="emergencyContact.region"
                          autocomplete="address-level1"
                          v-validate="'required'"
                          :data-vv-as="String($t('editShort.form.label_state_province')).toLowerCase()">
                    <option v-for="region in emergencyContact.regions"
                            :value="region.ISOCode">
                      {{ region[$validator.locale].mainTitle }}
                    </option>
                  </select>
                  <div class="text-red">
                    {{ errors.first('contact.region') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="emergencyContactPostalCode">{{ $t('editShort.form.label_postal_code') }}</label>
                  <input type="text" id="emergencyContactPostalCode" name="postalCode"
                         v-validate="'required'"
                         v-model="emergencyContact.postalCode" autocomplete="postal-code"
                         :data-vv-as="String($t('editShort.form.label_postal_code')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('emergencyContact.postalCode') }}
                  </div>
                </div>

                <div class="col-xs-12 col-md-6 item">
                  <label for="emergencyContactCellPhone">
                    {{$t('editShort.form.label_business_cell_phone')}}</label>
                  <input type="text" id="emergencyContactCellPhone"
                         name="cellPhone"
                         placeholder="*** *** ****"
                         v-validate="'required'"
                         v-model="emergencyContact.cellPhone" autocomplete="tel"
                         :data-vv-as="String($t('editShort.form.label_business_cell_phone')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('emergencyContact.cellPhone') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-6 item">
                  <label for="emergencyContactHomePhone">
                    {{ $t('editShort.form.label_home_phone') }}</label>
                  <input type="text" id="emergencyContactHomePhone"
                         name="homePhone"
                         placeholder="*** *** ****"
                         v-validate="'required'"
                         v-model="emergencyContact.homePhone" autocomplete="tel"
                         :data-vv-as="String($t('editShort.form.label_home_phone')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('emergencyContact.homePhone') }}
                  </div>
                </div>

              </div>
              <div class="row">
                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                <div class="col-xs-12">
                  <div v-if="emergencyContact.errorMessages.length > 0"
                       class="alert alert-danger animated fadeInDown">
                    <ul v-if="emergencyContact.errorMessages.length > 1" class="mt-0">
                      <li class="w-100 text-left font-weight-normal"
                          v-for="message in emergencyContact.errorMessages">
                        {{ message }}
                      </li>
                    </ul>
                    <span v-else>{{ emergencyContact.errorMessages[0] }}</span>
                  </div>
                </div>
                <div class="col-xs-12 text-right">
                  <button class="comment-submit text-white" :disabled="emergencyContact.submitting === true">
                    <span v-if="emergencyContact.submitting === false">{{ $t('editShort.form.label_next') }}</span>
                    <i class="fa fa-spinner fa-spin"
                       v-if="emergencyContact.submitting === true"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="panel panel-default">
        <div class="panel-heading panel-heading--disabled" role="tab" id="physicianInformationHeading">
          <h2 class="panel-title">
            <a role="button" href="#physicianInformationSection" aria-expanded="true"
               aria-controls="physicianInformationSection">
              {{ $t('editShort.form.title_physician') }}
            </a>
          </h2>
        </div>
        <div id="physicianInformationSection" class="panel-collapse collapse" role="tabpanel"
             aria-labelledby="physicianInformationHeading">
          <div class="panel-body">
            <form action="#" method="post" @submit.prevent="validateBeforeSubmit('physicianInformation')"
                  data-vv-scope="physicianInformation">
              <div class="row flex flex-wrap">

                <div class="col-xs-12 col-md-4 item">
                  <label for="physicianFirstName">{{ $t('editShort.form.label_first_name') }}</label>
                  <input type="text" id="physicianFirstName" name="firstName" v-validate="'required'"
                         v-model="physicianInformation.firstName" autocomplete="fname"
                         :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('physicianInformation.firstName') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="physicianLastName">{{ $t('editShort.form.label_last_name') }}</label>
                  <input type="text" id="physicianLastName" name="lastName" v-validate="'required'"
                         v-model="physicianInformation.lastName" autocomplete="lname"
                         :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('physicianInformation.lastName') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="physicianInformationPhone">{{ $t('editShort.form.label_phone') }}</label>
                  <input type="text" id="physicianInformationPhone" name="physicianPhone" v-validate="'required'"
                         placeholder="*** *** ****"
                         v-model="physicianInformation.physicianPhone" autocomplete="tel"
                         :data-vv-as="String($t('editShort.form.label_phone')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('physicianInformation.physicianPhone') }}
                  </div>
                </div>
                <div class="col-xs-12 item">
                  <label for="physicianInformationYearsAsPrimaryCaregiver">{{
                    $t('editShort.form.label_yearsAsPrimaryCaregiver') }}</label>
                  <input type="text" id="physicianInformationYearsAsPrimaryCaregiver" name="yearsAsPrimaryCaregiver"
                         v-validate="'required'"
                         v-model="physicianInformation.yearsAsPrimaryCaregiver"
                         :data-vv-as="String($t('editShort.form.label_yearsAsPrimaryCaregiver')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('physicianInformation.yearsAsPrimaryCaregiver') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="clinicName">{{ $t('editShort.form.label_clinicName') }}</label>
                  <input type="text" id="clinicName" name="clinicName" v-validate="'required'"
                         v-model="physicianInformation.clinicName"
                         :data-vv-as="String($t('editShort.form.label_clinicName')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('physicianInformation.clinicName') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="clinicAddress">{{ $t('editShort.form.label_clinicAddress') }}</label>
                  <input type="text" id="clinicAddress" name="clinicAddress" v-validate="'required'"
                         v-model="physicianInformation.address"
                         :data-vv-as="String($t('editShort.form.label_clinicAddress')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('physicianInformation.clinicAddress') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 item">
                  <label for="clinicPhone">{{ $t('editShort.form.label_phone') }}</label>
                  <input type="text" id="clinicPhone" name="clinicPhone" v-validate="'required'"
                         placeholder="*** *** ****"
                         v-model="physicianInformation.clinicPhone"
                         :data-vv-as="String($t('editShort.form.label_phone')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('physicianInformation.clinicPhone') }}
                  </div>
                </div>
              </div>

              <div class="row">
                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                <div class="col-xs-12">
                  <div v-if="physicianInformation.errorMessages.length > 0"
                       class="alert alert-danger animated fadeInDown">
                    <ul v-if="physicianInformation.errorMessages.length > 1" class="mt-0">
                      <li class="w-100 text-left font-weight-normal"
                          v-for="message in physicianInformation.errorMessages">
                        {{ message }}
                      </li>
                    </ul>
                    <span v-else>{{ physicianInformation.errorMessages[0] }}</span>
                  </div>
                </div>
                <div class="col-xs-12 text-right">
                  <button class="comment-submit text-white" :disabled="physicianInformation.submitting === true">
                    <span
                      v-if="physicianInformation.submitting === false">{{ $t('editShort.form.label_next') }}</span>
                    <i class="fa fa-spinner fa-spin"
                       v-if="physicianInformation.submitting === true"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      -->

      <div class="panel panel-default">
        <div class="panel-heading panel-heading--disabled" role="tab" id="medicalInformationHeading">
          <h2 class="panel-title">
            <a role="button" href="#medicalInformationSection" aria-expanded="true"
               aria-controls="medicalInformationSection">
              {{ $t('editShort.form.title_medical_information') }}
            </a>
          </h2>
        </div>
        <div id="medicalInformationSection" class="panel-collapse collapse" role="tabpanel"
             aria-labelledby="medicalInformationHeading">
          <div class="panel-body">
            <form action="#" method="post" @submit.prevent="validateBeforeSubmit('medicalInformation')"
                  data-vv-scope="medicalInformation">
              <div class="row flex flex-wrap">

                <div class="col-xs-12 col-md-6 item">
                  <label for="medicalAlertsAllergies">
                    {{ $t('editShort.form.label_medicalAlertsAllergies') }}</label>
                  <input type="text" id="medicalAlertsAllergies" name="alertsAllergies"
                         v-model="medicalInformation.medicalAlertsAllergies">
                </div>
                <div class="col-xs-12 col-md-6 item">
                  <label for="otherPrescriptions">
                    {{ $t('editShort.form.label_otherPrescriptions') }}</label>
                  <input type="text" id="otherPrescriptions" name="otherPrescriptions"
                         v-model="medicalInformation.otherPrescriptions">
                </div>
                <div class="col-xs-12 item">
                  <label for="otherSpecialNeeds">
                    {{ $t('editShort.form.label_otherSpecialNeeds') }}</label>
                  <input type="text" id="otherSpecialNeeds" name="otherSpecialNeeds"
                         v-model="medicalInformation.otherSpecialNeeds">
                </div>

                <div class="col-xs-12 col-md-6 item">
                  <fieldset>
                    <legend>{{ $t('editShort.form.label_diabetesManagementWith') }}</legend>
                    <label class="item float-left mr-8">
                      <input type="radio" name="diabetesManagementWith"
                             v-model="medicalInformation.diabetesManagementWith"
                             value="tournamentRegistration-diabetesManagement-pump" v-validate="'required'"
                             :data-vv-as="String($t('editShort.form.label_diabetesManagementWith')).toLowerCase()">
                      <span class="name">{{ $t('tournamentRegistration.diabetesManagement.pump') }}</span>
                    </label>
                    <label class="item float-left">
                      <input type="radio" name="diabetesManagementWith"
                             v-model="medicalInformation.diabetesManagementWith"
                             value="tournamentRegistration-diabetesManagement-mdi">
                      <span class="name">{{ $t('tournamentRegistration.diabetesManagement.mdi') }}</span>
                    </label>
                  </fieldset>
                  <div class="text-red">
                    {{ errors.first('medicalInformation.diabetesManagementWith') }}
                  </div>

                  <transition name="fade" enter-active-class="animated fadeInDown"
                              leave-active-class="animated fadeOutUp">
                    <div
                      v-if="medicalInformation.diabetesManagementWith === 'tournamentRegistration-diabetesManagement-pump'">
                      <label for="pumpBrand">{{$t('editShort.form.label_pumpBrand')}}</label>
                      <input
                        v-validate="{ required: requiredIfPump}"
                        type="text" id="pumpBrand" name="pumpBrand"
                        v-model="medicalInformation.pumpBrand"
                        :data-vv-as="String($t('editShort.form.label_pumpBrand')).toLowerCase()">
                      <div class="text-red">
                        {{ errors.first('medicalInformation.pumpBrand') }}
                      </div>
                    </div>
                  </transition>
                  <transition name="fade" enter-active-class="animated fadeInDown"
                              leave-active-class="animated fadeOutUp">
                    <div
                      v-if="medicalInformation.diabetesManagementWith === 'tournamentRegistration-diabetesManagement-mdi'">
                      <label for="pumpBrand">{{$t('editShort.form.label_mdiHowOften')}}</label>
                      <input type="text" id="mdiHowOften" name="mdiHowOften" v-model="medicalInformation.mdiHowOften"
                             v-validate="{ required: requiredIfMdi}"
                             :data-vv-as="String($t('editShort.form.label_mdiHowOften')).toLowerCase()">
                      <div class="text-red">
                        {{ errors.first('medicalInformation.mdiHowOften') }}
                      </div>
                    </div>
                  </transition>
                </div>

                <div class="col-xs-12 col-md-6 item">
                  <fieldset>
                    <legend>{{ $t('editShort.form.label_haveContinuousGlucoseMonitor') }}</legend>
                    <label class="item float-left mr-8">
                      <input type="radio" name="haveContinuousGlucoseMonitor"
                             v-model="medicalInformation.haveContinuousGlucoseMonitor"
                             value="1" v-validate="'required'"
                             :data-vv-as="String($t('editShort.form.label_haveContinuousGlucoseMonitor')).toLowerCase()">
                      <span class="name">{{ $t('editShort.form.label_yes') }}</span>
                    </label>
                    <label class="item float-left">
                      <input type="radio" name="haveContinuousGlucoseMonitor"
                             v-model="medicalInformation.haveContinuousGlucoseMonitor"
                             value="0">
                      <span class="name">{{ $t('editShort.form.label_no') }}</span>
                    </label>
                  </fieldset>
                  <div class="text-red">
                    {{ errors.first('medicalInformation.haveContinuousGlucoseMonitor') }}
                  </div>
                  <transition name="fade" enter-active-class="animated fadeInDown"
                              leave-active-class="animated fadeOutUp">
                    <div v-if="parseInt(medicalInformation.haveContinuousGlucoseMonitor, 10) === 1">
                      <label for="continuousGlucoseMonitorBrand">
                        {{$t('editShort.form.label_continuousGlucoseMonitorBrand')}}</label>
                      <input
                        v-validate="{ required: requiredIfHasCgm}"
                        type="text" id="continuousGlucoseMonitorBrand" name="continuousGlucoseMonitorBrand"
                        v-model="medicalInformation.continuousGlucoseMonitorBrand"
                        :data-vv-as="String($t('editShort.form.label_continuousGlucoseMonitorBrand')).toLowerCase()">
                      <div class="text-red">
                        {{ errors.first('medicalInformation.continuousGlucoseMonitorBrand') }}
                      </div>
                    </div>
                  </transition>
                </div>
                <div class="col-xs-12 col-md-6 item">
                  <label for="glucoseMonitorBrand">
                    {{ $t('editShort.form.label_glucoseMonitorBrand') }}
                  </label>
                  <input type="text" id="glucoseMonitorBrand" name="glucoseMonitorBrand"
                         v-validate="'required'"
                         v-model="medicalInformation.glucoseMonitorBrand"
                         :data-vv-as="String($t('editShort.form.label_glucoseMonitorBrand')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('medicalInformation.glucoseMonitorBrand') }}
                  </div>
                </div>
                <div class="col-xs-12 col-md-6 item">
                  <label for="preferredInsulinType">
                    {{ $t('editShort.form.label_preferredInsulinType') }}
                  </label>
                  <input type="text" id="preferredInsulinType" name="preferredInsulinType"
                         v-validate="'required'"
                         v-model="medicalInformation.preferredInsulinType"
                         :data-vv-as="String($t('editShort.form.label_preferredInsulinType')).toLowerCase()">
                  <div class="text-red">
                    {{ errors.first('medicalInformation.preferredInsulinType') }}
                  </div>
                </div>
                <div class="col-xs-12 filter-item item px-6">
                  <input class="filter-check" type="checkbox" id="agreesToTerms" name="agreesToTerms"
                         v-validate="'required'"
                         :data-vv-as="String($t('editShort.form.label_agreesToThe') + ' ' + privacyPolicyLabel).toLowerCase()"
                         v-model="medicalInformation.agreesToTerms">
                  <label for="agreesToTerms">
                    {{$t('editShort.form.label_agreesToThe')}} <a target="_blank" :href="privacyPolicyLink">{{ privacyPolicyLabel }}</a>
                  </label>
                </div>
                <div class="col-xs-12 text-red">
                  {{ errors.first('medicalInformation.agreesToTerms') }}
                </div>
              </div>

              <div class="row">
                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                <div class="col-xs-12">
                  <div v-if="medicalInformation.errorMessages.length > 0"
                       class="alert alert-danger animated fadeInDown">
                    <ul v-if="medicalInformation.errorMessages.length > 1" class="mt-0">
                      <li class="w-100 text-left font-weight-normal"
                          v-for="message in medicalInformation.errorMessages">
                        {{ message }}
                      </li>
                    </ul>
                    <span v-else>{{ medicalInformation.errorMessages[0] }}</span>
                  </div>
                </div>
                <div class="col-xs-12 text-right">
                  <button class="comment-submit text-white" :disabled="medicalInformation.submitting === true">
                    <span v-if="medicalInformation.submitting === false">{{$t('editShort.form.label_send')}}</span>
                    <i class="fa fa-spinner fa-spin"
                       v-if="medicalInformation.submitting === true"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
    <div v-if="successMessage.length > 0" class="alert alert-success animated fadeInDown">
      <span>{{ successMessage }}</span>
    </div>
    <div v-else-if="errorMessages.length > 0" class="alert alert-danger animated fadeInDown">
      <ul v-if="errorMessages.length > 1" class="mt-0">
        <li class="w-100 text-left font-weight-normal"
            v-for="message in errorMessages">
          {{ message }}
        </li>
      </ul>
      <span v-else>{{ errorMessages[0] }}</span>
    </div>
  </div>
</template>

<script>
	import axios from 'axios';
	import 'pikaday/css/pikaday.css';

	import Pikaday from 'pikaday';

	let bsn = require('bootstrap.native');
	let playerInformationCollapse, playerProfileCollapse, parentCollapse,
		/* contactCollapse, emergencyContactCollapse, physicianInformationCollapse, */
		medicalInformationCollapse;
	let now = new Date();

	export default {
		name: 'tournamentRegistration',
		props: ['tournamentRegistrationTypeGuid', 'languageIndex', 'privacyPolicyLink', 'privacyPolicyLabel'],
		data: function () {
			let data = {
				playerInformation: {
					firstName: '',
					middleName: '',
					lastName: '',
					phone: '',
					email: '',
					address: '',
					city: '',
					country: '',
					region: '',
					regions: [],
					postalCode: '',
					submitting: false,
					submitted: false,
					errorMessages: []
				},
				playerProfile: {
					gender: '',
					age: '',
					dateOfBirth: '',
					datePicker: undefined,
					height: '',
					weight: '',
					shootLeftRight: '',
					position: '',
					yearsPlayed: '',
					attendedDskateBefore: '',
					currentHockeyTier: '',
					currentHockeyLevel: '',
					playerPlays: '',
					gamesPlayed: '',
					goals: '',
					assists: '',
					penaltyMinutes: '',
					linePlayed: '',
					otherExperience: '',
					submitting: false,
					submitted: false,
					errorMessages: []
				},
				parents: {
					parents: [],
					submitting: false,
					submitted: false,
					errorMessages: []
				},
				contact: {
					firstName: '',
					lastName: '',
					gender: '',
					dateOfBirth: '',
					address: '',
					city: '',
					country: '',
					region: '',
					postalCode: '',
					email: '',
					phone: '',
					healthcardNumber: '',
					expiryDate: '',
					dateOfBirthPicker: undefined,
					expiryDatePicker: undefined,
					submitting: false,
					submitted: false,
					errorMessages: [],
					regions: []
				},
				emergencyContact: {
					relation: '',
					firstName: '',
					lastName: '',
					cellPhone: '',
					homePhone: '',
					address: '',
					city: '',
					country: '',
					region: '',
					postalCode: '',
					regions: [],
					submitting: false,
					submitted: false,
					errorMessages: []
				},
				physicianInformation: {
					firstName: '',
					lastName: '',
					physicianPhone: '',
					yearsAsPrimaryCaregiver: '',
					clinicName: '',
					address: '',
					clinicPhone: '',
					submitting: false,
					submitted: false,
					errorMessages: []
				},
				medicalInformation: {
					medicalAlertsAllergies: '',
					otherPrescriptions: '',
					otherSpecialNeeds: '',
					diabetesManagementWith: '',
					pumpBrand: '', //only required if diabetesManagementWith is tournamentForm-diabetesManagement-pump
					mdiHowOften: '',
					continuousGlucoseMonitorBrand: '',
					haveContinuousGlucoseMonitor: 0,
					glucoseMonitorBrand: '',
					preferredInsulinType: '',
					submitting: false,
					submitted: false,
					errorMessages: []
				},
				pikadayOptions: {},
				countries: {},
				regions: {},
				errorMessages: [],
				successMessage: ''
			};

			if (this.languageIndex === 'fr') {
				data.pikadayOptions.i18n = {
					previousMonth: 'Le mois précédent',
					nextMonth: 'Le mois prochain',
					months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
					weekdays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
					weekdaysShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']
				};
				data.pikadayOptions.firstDay = 1;
			}
			return data;
		},
		created() {
			axios.get('/api/v1/geo-countries/')
				.then(response => {
					this.countries = Object.values(response.data.data['geo-country']);
					return axios.get('/api/v1/geo-country-regions/')
				})
				.then(response => {
					this.regions = Object.values(response.data.data['geo-country-region']);
					return Promise.resolve();
				})
				.then(() => {
					return axios.post(
						'/process/tournamentRegistration.php',
						{action: 'retrieveFormData'},
						{headers: {'Content-Type': 'application/json'}}
					);
				})
				.then(response => {

					//Load data where user left off
					if (response.data.data !== null) {
						if ('playerInformation' in response.data.data) {
							this.playerInformation = Object.assign(this.playerInformation, response.data.data.playerInformation);
							this.countryOnChange('playerInformation');
							this.playerInformation.region = response.data.data.playerInformation.region;
						}
						if ('playerProfiles' in response.data.data) {
							this.playerProfile = Object.assign(this.playerProfile, response.data.data.playerProfiles);
						}
						if ('parents' in response.data.data) {
							this.parents.parents = Object.values(response.data.data.parents.parents);
							this.parents.parents.forEach((parent, index) => {
								this.parentCountryOnChange(index);
								parent.region = response.data.data.parents.parents[index].region;
							});
						}
						/*
						if ('contact' in response.data.data) {
							this.contact = Object.assign(this.contact, response.data.data.contact);
							this.countryOnChange('contact');
							this.contact.region = response.data.data.contact.region;
						}
						if ('emergencyContact' in response.data.data) {
							this.emergencyContact = Object.assign(this.emergencyContact, response.data.data.emergencyContact);
							this.countryOnChange('emergencyContact');
							this.emergencyContact.region = response.data.data.emergencyContact.region;
						}
						if ('physicianInformation' in response.data.data) {
							this.physicianInformation = Object.assign(this.physicianInformation, response.data.data.physicianInformation);
						}
						*/
						if ('medicalInformation' in response.data.data) {
							this.medicalInformation = Object.assign(this.medicalInformation, response.data.data.medicalInformation);
						}
					}


				});
		},
		mounted() {
			let currentVueComponent = this;
			this.$nextTick(() => {

				//Initialize accordion
				playerInformationCollapse = new bsn.Collapse(document.querySelector('#playerInformationHeading a'), {parent: '#accordion'});
				playerProfileCollapse = new bsn.Collapse(document.querySelector('#playerProfileHeading a'), {parent: '#accordion'});
				parentCollapse = new bsn.Collapse(document.querySelector('#parentsHeading a'), {parent: '#accordion'});
				//contactCollapse = new bsn.Collapse(document.querySelector('#contactHeading a'), {parent: '#accordion'});
				//emergencyContactCollapse = new bsn.Collapse(document.querySelector('#emergencyContactHeading a'), {parent: '#accordion'});
				//physicianInformationCollapse = new bsn.Collapse(document.querySelector('#physicianInformationHeading a'), {parent: '#accordion'});
				medicalInformationCollapse = new bsn.Collapse(document.querySelector('#medicalInformationHeading a'), {parent: '#accordion'});

				//Add events for opening and closing panels
				document.getElementById('playerInformationSection').addEventListener('shown.bs.collapse', () => {
					this.scrollToSection('playerInformation');
				});

				document.getElementById('playerProfileSection').addEventListener('shown.bs.collapse', () => {
					this.startPlayerProfileDatePicker();
					this.scrollToSection('playerProfileSection');
				});

				document.getElementById('parentsSection').addEventListener('show.bs.collapse', () => {
					if (this.parents.parents.length === 0) {
						this.addParent();
					}
				});
				document.getElementById('parentsSection').addEventListener('shown.bs.collapse', () => {
					this.scrollToSection('parents');
				});

				/*
				document.getElementById('contactSection').addEventListener('shown.bs.collapse', () => {
					this.startContactDatePickers();
					this.scrollToSection('contact');
				});

				document.getElementById('emergencyContactSection').addEventListener('shown.bs.collapse', () => {
					this.scrollToSection('emergencyContact');
				});
				 */

				document.getElementById('medicalInformationSection').addEventListener('shown.bs.collapse', () => {
					//These radio button control appearance, so they have to be updated manually
					let hasCGM = parseInt(currentVueComponent.medicalInformation.haveContinuousGlucoseMonitor, 10);
					if (hasCGM === 1) {
						currentVueComponent.medicalInformation.haveContinuousGlucoseMonitor = 0;
						currentVueComponent.medicalInformation.haveContinuousGlucoseMonitor = hasCGM;
					}

					this.scrollToSection('medicalInformation');

				});
			});
		},
		computed: {
			requiredIfPump() {
				return this.medicalInformation.diabetesManagementWith === 'tournamentRegistration-diabetesManagement-pump';
			},
			requiredIfMdi() {
				return this.medicalInformation.diabetesManagementWith === 'tournamentRegistration-diabetesManagement-mdi';
			},
			requiredIfHasCgm() {
				return parseInt(this.medicalInformation.haveContinuousGlucoseMonitor, 10) === 1;
			}
		},
		methods: {
			startPlayerProfileDatePicker() {
				let currentVueComponent = this;
				if (currentVueComponent.playerProfile.datePicker === undefined) {
					let options = {}, currentDateOfBirth;
					const player = currentVueComponent.playerProfile;

					options = Object.assign({
							field: document.getElementById('playerProfileDateOfBirth'),
							//Why an extra 2 years?
							//- 1 extra year for someone who super-loves hockey
							//- 1 extra year for a cheap bug fix
							yearRange: [now.getFullYear() - 30, now.getFullYear() - 6],
							onSelect: function (date) {
								player.dateOfBirth = '' + date.getFullYear() + '-';
								player.dateOfBirth += String(date.getMonth() + 1).padStart(2, '0') + '-';
								player.dateOfBirth += String(date.getDate()).padStart(2, '0');
							}
						},
						currentVueComponent.pikadayOptions
					);
					currentVueComponent.playerProfile.datePicker = new Pikaday(options);
					if (player.dateOfBirth.length > 0) {
						currentVueComponent.playerProfile.datePicker.setDate(new Date(player.dateOfBirth + 'T00:00:00'));
						currentDateOfBirth = currentVueComponent.playerProfile.datePicker.getDate();

						//player.dateOfBirth = '' + currentDateOfBirth.getFullYear() + '-';
						//player.dateOfBirth += String(currentDateOfBirth.getMonth()).padStart(2, '0') + '-';
						//player.dateOfBirth += String(currentDateOfBirth.getDate()).padStart(2, '0');
					} else {
						currentVueComponent.playerProfile.datePicker.setDate(new Date('2000-01-01T00:00:00'));
					}
				}
			},
			/*
    startContactDatePickers() {
      let currentVueComponent = this;
      let dateOfBirthOptions = {}, expiryOptions = {}, currentDateOfBirth, currentExpiry;
      const contact = currentVueComponent.contact;
      if (currentVueComponent.contact.dateOfBirthPicker === undefined) {
        dateOfBirthOptions = Object.assign({
            field: document.getElementById('contactDateOfBirth'),
            //Why an extra 2 years?
            //- 1 extra year for someone who super-loves hockey
            //- 1 extra year for a cheap bug fix
            yearRange: [now.getFullYear() - 30, now.getFullYear() - 6],
            onSelect: function (date) {
              contact.dateOfBirth = '' + date.getFullYear() + '-';
              contact.dateOfBirth += String(date.getMonth() + 1).padStart(2, '0') + '-';
              contact.dateOfBirth += String(date.getDate()).padStart(2, '0');
            }
          },
          currentVueComponent.pikadayOptions
        );

        currentVueComponent.contact.dateOfBirthPicker = new Pikaday(dateOfBirthOptions);
        currentVueComponent.contact.dateOfBirthPicker.setDate(new Date(contact.dateOfBirth + 'T00:00:00'));
        if (contact.dateOfBirth.length > 0) {
          currentDateOfBirth = currentVueComponent.contact.dateOfBirthPicker.getDate();

          //contact.dateOfBirth = '' + currentDateOfBirth.getFullYear() + '-';
          //contact.dateOfBirth += String(currentDateOfBirth.getMonth()).padStart(2, '0') + '-';
          //contact.dateOfBirth += String(currentDateOfBirth.getDate()).padStart(2, '0');
        } else {
          currentVueComponent.contact.dateOfBirthPicker.setDate('2000-01-01T00:00:00');

        }
      }

      if (currentVueComponent.contact.expiryDatePicker === undefined) {
        expiryOptions = Object.assign({
            field: document.getElementById('contactExpiryDate'),
            //, yearRange: [now.getFullYear(), now.getFullYear() + 4]
            onSelect: function (date) {
              contact.expiryDate = '' + date.getFullYear() + '-';
              contact.expiryDate += String(date.getMonth() + 1).padStart(2, '0') + '-';
              contact.expiryDate += String(date.getDate()).padStart(2, '0');
            }
          },
          currentVueComponent.pikadayOptions
        );
        currentVueComponent.contact.expiryDatePicker = new Pikaday(expiryOptions);
        if (contact.expiryDate.length > 0) {
          currentVueComponent.contact.expiryDatePicker.setDate(new Date(contact.expiryDate + 'T00:00:00'));
          currentExpiry = currentVueComponent.contact.expiryDatePicker.getDate();

          //contact.expiryDate = '' + currentExpiry.getFullYear() + '-';
          //contact.expiryDate += String(currentExpiry.getMonth()).padStart(2, '0') + '-';
          //contact.expiryDate += String(currentExpiry.getDate()).padStart(2, '0');
        } else {
          currentVueComponent.contact.datePicker.setDate('2000-01-01T00:00:00');
        }
      }
    },
    */
			countryOnChange(section) {
				let selectedCountry = this.countries.find(country => {
					return country.ISOCode === this[section].country;
				});
				this[section].regions = this.regions.filter(region => {
					return selectedCountry.ID === region.geographyCountryID;
				});
			},
			validateDateOfBirth(dateFieldName) {
				this.$validator.validate(
					dateFieldName,
					this[dateFieldName]
				)
					.then(response => {
						console.log(response);
					});
			},
			addParent() {
				let newParent = {
					relation: '',
					firstName: '',
					lastName: '',
					email: '',
					address: '',
					city: '',
					country: '',
					region: '',
					postalCode: '',
					cellPhone: '',
					homePhone: '',
					regions: ''
				};
				this.parents.parents.push(newParent);
			},
			removeParent(index) {
				this.parents.parents.splice(index, 1);
			},

			parentCountryOnChange(index) {
				let selectedCountry = this.countries.find(country => {
					return country.ISOCode === this.parents.parents[index].country;
				});
				this.parents.parents[index].regions = this.regions.filter(region => {
					return selectedCountry.ID === region.geographyCountryID;
				});
			},
			parentCopyAddress(index) {
				this.parents.parents[index].email = this.contact.email;
				this.parents.parents[index].address = this.contact.address;
				this.parents.parents[index].city = this.contact.city;
				this.parents.parents[index].country = this.contact.country;
				this.parentCountryOnChange(index);
				this.parents.parents[index].region = this.contact.region;
				this.parents.parents[index].postalCode = this.contact.postalCode;
				this.parents.parents[index].homePhone = this.contact.phone
			},

			/**
			 * In v-for, error messages get a bit dicey. Use this instead of errors.first()
			 * @param fieldName
			 * @returns {*|string}
			 */
			getErrorMessageFor(fieldName) {
				let errorItem = this.errors.items.find((item) => {
					return item.field === fieldName
				});
				let errorMessage = typeof errorItem !== 'undefined' ? errorItem.msg : '';
				return errorMessage;
			},

			validateBeforeSubmit(scope) {
				let currentVueComponent = this;
				this.$validator.validateAll(scope).then(result => {
					if (result) {
						switch (scope) {
							case 'playerInformation':
								this.submit('playerInformation',
									{
										action: 'registerPlayerInformation',
										firstName: this.playerInformation.firstName,
										middleName: this.playerInformation.middleName,
										lastName: this.playerInformation.lastName,
										phone: this.playerInformation.phone,
										email: this.playerInformation.email,
										address: this.playerInformation.address,
										city: this.playerInformation.city,
										country: this.playerInformation.country,
										region: this.playerInformation.region,
										postalCode: this.playerInformation.postalCode
									},
									function doOnSuccess() {
										//Hide current section
										playerInformationCollapse.hide();
										document.getElementById('playerInformationSection').classList.remove('in');

										//Show next section
										document.getElementById('playerProfileHeading').classList.remove('panel-heading--disabled');
										playerProfileCollapse.show();
									});
								break;
							case 'playerProfile':
								this.submit('playerProfile',
									{
										action: 'registerPlayerProfile',
										gender: this.playerProfile.gender,
										age: this.playerProfile.age,
										dateOfBirth: this.playerProfile.dateOfBirth,
										height: this.playerProfile.height,
										weight: this.playerProfile.weight,
										shootLeftRight: this.playerProfile.shootLeftRight,
										position: this.playerProfile.position,
										yearsPlayed: this.playerProfile.yearsPlayed,
										attendedDskateBefore: this.playerProfile.attendedDskateBefore,
										currentHockeyTier: this.playerProfile.currentHockeyTier,
										currentHockeyLevel: this.playerProfile.currentHockeyLevel,
										playerPlays: this.playerProfile.playerPlays,
										gamesPlayed: this.playerProfile.gamesPlayed,
										goals: this.playerProfile.goals,
										assists: this.playerProfile.assists,
										penaltyMinutes: this.playerProfile.penaltyMinutes,
										linePlayed: this.playerProfile.linePlayed,
										playerRequest: this.playerProfile.playerRequest,
										otherExperience: this.playerProfile.otherExperience
									},
									function doOnSuccess() {

										//Hide current section
										playerProfileCollapse.hide();
										document.getElementById('playerProfileSection').classList.remove('in');

										//Show next section
										document.getElementById('parentsHeading').classList.remove('panel-heading--disabled');
										parentCollapse.show();

									}
								);
								break;
							case 'parents':
								this.submit('parents',
									{
										action: 'registerParents',
										parents: this.parents.parents
									},
									function doOnSuccess() {
										parentCollapse.hide();
										document.getElementById('parentsSection').classList.remove('in');

										document.getElementById('medicalInformationHeading').classList.remove('panel-heading--disabled');
										medicalInformationCollapse.show();
									}
								);
								break;
							/*
            case 'contact':
              this.submit('contact',
                {
                  action: 'registerContact',
                  firstName: this.contact.firstName,
                  lastName: this.contact.lastName,
                  gender: this.contact.gender,
                  dateOfBirth: this.contact.dateOfBirth,
                  address: this.contact.address,
                  city: this.contact.city,
                  country: this.contact.country,
                  region: this.contact.region,
                  postalCode: this.contact.postalCode,
                  phone: this.contact.phone,
                  email: this.contact.email,
                  healthcardNumber: this.contact.healthcardNumber,
                  expiryDate: this.contact.expiryDate
                },
                function doOnSuccess() {

                  //Hide current section
                  contactCollapse.hide();
                  document.getElementById('contactSection').classList.remove('in');

                  //Show next section
                  document.getElementById('emergencyContactHeading').classList.remove('panel-heading--disabled');
                  emergencyContactCollapse.show();

                }
              );
              break;
            case 'emergencyContact':
              this.submit('emergencyContact',
                {
                  action: 'registerEmergencyContact',
                  relation: this.emergencyContact.relation,
                  firstName: this.emergencyContact.firstName,
                  lastName: this.emergencyContact.lastName,
                  cellPhone: this.emergencyContact.cellPhone,
                  homePhone: this.emergencyContact.homePhone,
                  address: this.emergencyContact.address,
                  city: this.emergencyContact.city,
                  country: this.emergencyContact.country,
                  region: this.emergencyContact.region,
                  postalCode: this.emergencyContact.postalCode
                },
                function doOnSuccess() {

                  emergencyContactCollapse.hide();
                  document.getElementById('emergencyContactSection').classList.remove('in');

                  document.getElementById('physicianInformationHeading').classList.remove('panel-heading--disabled');
                  physicianInformationCollapse.show();

                }
              );
              break;
            case 'physicianInformation':
              this.submit('physicianInformation',
                {
                  action: 'registerPhysicianInformation',
                  firstName: this.physicianInformation.firstName,
                  lastName: this.physicianInformation.lastName,
                  physicianPhone: this.physicianInformation.physicianPhone,
                  yearsAsPrimaryCaregiver: this.physicianInformation.yearsAsPrimaryCaregiver,
                  clinicName: this.physicianInformation.clinicName,
                  address: this.physicianInformation.address,
                  clinicPhone: this.physicianInformation.clinicPhone
                },
                function doOnSuccess() {

                  physicianInformationCollapse.hide();
                  document.getElementById('physicianInformationSection').classList.remove('in');

                  document.getElementById('medicalInformationHeading').classList.remove('panel-heading--disabled');
                  medicalInformationCollapse.show();
                }
              );
              break;
               */
							case 'medicalInformation':
								this.submit('medicalInformation',
									{
										action: 'registerMedicalInformation',
										medicalAlertsAllergies: this.medicalInformation.medicalAlertsAllergies,
										otherPrescriptions: this.medicalInformation.otherPrescriptions,
										otherSpecialNeeds: this.medicalInformation.otherSpecialNeeds,
										diabetesManagementWith: this.medicalInformation.diabetesManagementWith,
										pumpBrand: this.medicalInformation.pumpBrand,
										mdiHowOften: this.medicalInformation.mdiHowOften,
										haveContinuousGlucoseMonitor: this.medicalInformation.haveContinuousGlucoseMonitor,
										continuousGlucoseMonitorBrand: this.medicalInformation.continuousGlucoseMonitorBrand,
										glucoseMonitorBrand: this.medicalInformation.glucoseMonitorBrand,
										preferredInsulinType: this.medicalInformation.preferredInsulinType,
										agreesToTerms: this.medicalInformation.agreesToTerms
									},
									function doOnSuccess() {
										currentVueComponent.medicalInformation.submitting = true;
										axios.post('/process/tournamentRegistration.php', {
											action: 'register'
										})
											.then(response => {
												if (response.data.success === true) {

													medicalInformationCollapse.hide();
													document.getElementById('medicalInformationHeading').classList.remove('in');

													currentVueComponent.successMessage = currentVueComponent.$t('editShort.form.success_message');

												} else if (response.data.messages.length > 0) {

													currentVueComponent.errorMessages = response.data.messages;

												} else {
													currentVueComponent.errorMessages.push(currentVueComponent.$t('editShort.form.error_message'));
												}

												currentVueComponent.medicalInformation.submitting = true;
											});
									}
								);
								break;
						}
					} else {
						this.scrollToError(scope);
					}
				});
			},
			/**
			 *
			 * @param {string} scope
			 * @param {object} formSectionData
			 * @param {function} doOnSuccess
			 */
			submit(scope, formSectionData, doOnSuccess) {
				let allData = Object.assign(formSectionData, {
					languageIndex: this.languageIndex,
					timestamp: this.$root.timestamp,
					tournamentRegistrationTypeGUID: this.tournamentRegistrationTypeGuid
				});

				//Start loading spinner
				this[scope].submitting = true;

				axios.post('/process/tournamentRegistration.php', allData,
					{headers: {'Content-Type': 'application/json'}})
					.then(response => {

						//Hide loading spinner
						this[scope].submitting = false;

						if (response.data.success === true) {

							//Clear error messages
							this[scope].errorMessages = [];

							doOnSuccess();

						} else if (response.data.messages.length > 0) {

							//Show error messages from back-end
							this[scope].errorMessages = response.data.messages;

						} else {

							//Show generic error message
							this[scope].errorMessages.push(this.$t('editShort.form.error_message'));

						}
					});
			},
		}
	}
</script>

<style scoped>

  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;

    margin-left: 15px;
    margin-right: 15px;
  }

  @media (min-width: 576px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;

      margin-left: 15px;
      margin-right: 15px;
    }
  }

  @media (min-width: 768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;

      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .panel {
    background-color: transparent;
  }

  .item input, .item select, .item textarea {
    margin-top: auto;
  }

  .hockey-experience .filter-check + label {
    width: 100%;
    padding-left: 2.5rem;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 33.3333%;
    max-width: 100%;
  }

  .leave-comment-wrap .item .name {
    display: inline;
  }

</style>

                                                       
