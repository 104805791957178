var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { id: "contactForm", action: "#", method: "post" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "item" }, [
            _c("label", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("editShort.form.label_name")) + " "),
                _c("i", [_vm._v("*")])
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "name",
                  autocomplete: "name",
                  "data-vv-as": String(
                    _vm.$t("editShort.form.label_name")
                  ).toLowerCase()
                },
                domProps: { value: _vm.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-red" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.errors.first("name")) +
                    "\n                    "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6" }, [
          _c("div", { staticClass: "item" }, [
            _c("label", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("editShort.form.label_email")) + " "),
                _c("i", [_vm._v("*")])
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email"
                  }
                ],
                attrs: {
                  type: "email",
                  name: "email",
                  autocomplete: "email",
                  "data-vv-as": String(
                    _vm.$t("editShort.form.label_email")
                  ).toLowerCase()
                },
                domProps: { value: _vm.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-red" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.errors.first("email")) +
                    "\n                    "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "item" }, [
            _c("label", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("editShort.form.label_subject")))
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.subject,
                    expression: "subject"
                  }
                ],
                attrs: {
                  type: "text",
                  name: "subject",
                  "data-vv-as": String(
                    _vm.$t("editShort.form.label_subject")
                  ).toLowerCase()
                },
                domProps: { value: _vm.subject },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.subject = $event.target.value
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "item" }, [
            _c("label", [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("editShort.form.label_message"))),
                _c("i", [_vm._v("*")])
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.message,
                    expression: "message"
                  }
                ],
                attrs: {
                  name: "message",
                  "data-vv-as": String(
                    _vm.$t("editShort.form.label_message")
                  ).toLowerCase()
                },
                domProps: { value: _vm.message },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.message = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-red" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.errors.first("message")) +
                    "\n                    "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.$root.timestamp,
              expression: "$root.timestamp"
            }
          ],
          attrs: { type: "hidden", name: "timestamp" },
          domProps: { value: _vm.$root.timestamp },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.$root, "timestamp", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12" }, [
          _vm.successMessage.length > 0
            ? _c(
                "div",
                { staticClass: "alert alert-success animated fadeInDown" },
                [
                  _c("span", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.successMessage) +
                        "\n                "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.errorMessages.length > 0
            ? _c(
                "div",
                { staticClass: "alert alert-danger animated fadeInDown" },
                [
                  _vm.errorMessages.length > 1
                    ? _c(
                        "ul",
                        { staticClass: "mt-0" },
                        _vm._l(_vm.errorMessages, function(message) {
                          return _c(
                            "li",
                            {
                              staticClass: "w-100 text-left font-weight-normal"
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(message) +
                                  "\n                    "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.errorMessages[0]) +
                            "\n                "
                        )
                      ])
                ]
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-12 text-right" }, [
          _c(
            "button",
            {
              staticClass: "comment-submit text-white",
              class: _vm.submitted ? "hidden" : "",
              attrs: { disabled: _vm.submitting === true }
            },
            [
              _vm.submitting === false
                ? _c("span", [
                    _vm._v(_vm._s(_vm.$t("editShort.form.label_send_message")))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.submitting === true
                ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                : _vm._e()
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }