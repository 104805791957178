
export function isMobile() {
	//return /android|webos|iphone|ipod|ipad|blackberry|IEMobile|iemobile|opera\ mini/i.test(navigator.userAgent.toLowerCase())
	if (screen.width > 1200) {
		return false;
	} else {
		return true;
	}
}


