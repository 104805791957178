/**
 * See https://jsfiddle.net/logaretm/vw0zw8et/
 * @param Vue
 * @param options
 */

export default {
	install(Vue, options) {
		Vue.prototype.scrollToError = function (section, index = '') {
			const firstField = Object.keys(this.errors.collect())[0];

			// this assumes you have a conviention of ref and field name here I just add the
			// Input suffix to the field name as you can see in the template.
			//this.$refs[`${firstField}`].scrollIntoView();
			document.querySelector('#' + section + 'Section [name=' + firstField + index + ']').scrollIntoView({
				behavior: 'smooth'
				, block: 'center'
			});
		};
		Vue.prototype.scrollToSection = function (section) {
			document.getElementById(section + 'Heading').scrollIntoView({
				behavior: 'smooth'
			});
		};
	}
};

