<!--
TODO: make only one phone number required
TODO: is it just me, or is there a bug where the gender doesn't save the first time you submit a player?
    Is it just a problem with two players?
-->
<template>
    <div>
        <div id="accordion" class="panel-group leave-comment-wrap pb-0" role="tablist" aria-multiselectable="true">
            <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="contactHeading">
                    <h2 class="panel-title">
                        <a role="button" href="#contactSection" aria-expanded="true" aria-controls="contactSection">
                            {{ $t('editShort.form.title_contact_information') }}
                        </a>
                    </h2>
                </div>
                <div id="contactSection" class="panel-collapse collapse in" role="tabpanel"
                     aria-labelledby="contactHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('contact')" data-vv-scope="contact">
                            <div class="row flex flex-wrap">
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="contactFirstName">{{ $t('editShort.form.label_first_name') }}</label>
                                    <input type="text" id="contactFirstName" name="firstName" v-validate="'required'"
                                           v-model="contact.firstName" autocomplete="fname"
                                           :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('contact.firstName') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="contactMiddleName">{{ $t('editShort.form.label_middle_name') }}</label>
                                    <input type="text" id="contactMiddleName" name="middleName"
                                           v-model="contact.middleName" autocomplete="mname">
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label for="contactLastName">{{ $t('editShort.form.label_last_name') }}</label>
                                    <input type="text" id="contactLastName" name="lastName" v-validate="'required'"
                                           v-model="contact.lastName" autocomplete="lname"
                                           :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('contact.lastName') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 item">
                                    <label for="contactPhone">{{ $t('editShort.form.label_phone') }}</label>
                                    <input type="text" id="contactPhone" name="phone" v-validate="'required'"
                                           v-model="contact.phone" autocomplete="tel"
                                           :data-vv-as="String($t('editShort.form.label_phone')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('contact.phone') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 item">
                                    <label for="contactEmail">{{ $t('editShort.form.label_email') }}</label>
                                    <input type="email" id="contactEmail" name="email" v-validate="'required'"
                                           v-model="contact.email" autocomplete="email"
                                           :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('contact.email') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label for="contactAddress">{{ $t('editShort.form.label_address') }}</label>
                                    <input type="text" id="contactAddress" name="address" v-validate="'required'"
                                           v-model="contact.address" autocomplete="street-address"
                                           :data-vv-as="String($t('editShort.form.label_address')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('contact.address') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 item">
                                    <label for="contactCountry">{{ $t('editShort.form.label_country') }}</label>
                                    <select id="contactCountry" name="country"
                                            autocomplete="country"
                                            v-on:change="countryOnChange('contact')"
                                            v-model="contact.country"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_country')).toLowerCase()">
                                        <option v-for="country in countries" v-bind:value="country.ISOCode">
                                            {{ country[$validator.locale].mainTitle }}
                                        </option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('contact.country') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <label for="contactCity">{{ $t('editShort.form.label_city') }}</label>
                                    <input type="text" id="contactCity" name="city" v-validate="'required'"
                                           v-model="contact.city" autocomplete="address-level2"
                                           :data-vv-as="String($t('editShort.form.label_city')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('contact.city') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 item">
                                    <label for="contactRegion">{{ $t('editShort.form.label_state_province') }}</label>
                                    <select id="contactRegion" name="region" v-model="contact.region"
                                            autocomplete="address-level1"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_state_province')).toLowerCase()">
                                        <option v-for="region in contact.regions"
                                                :value="region.ISOCode">
                                            {{ region[$validator.locale].mainTitle }}
                                        </option>
                                    </select>
                                    <div class="text-red">
                                        {{ errors.first('contact.region') }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <label for="contactPostalCode">{{ $t('editShort.form.label_postal_code') }}</label>
                                    <input type="text" id="contactPostalCode" name="postalCode"
                                           v-validate="'required'"
                                           v-model="contact.postalCode" autocomplete="postal-code"
                                           :data-vv-as="String($t('editShort.form.label_postal_code')).toLowerCase()">
                                    <div class="text-red">
                                        {{ errors.first('contact.postalCode') }}
                                    </div>
                                </div>
                                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                                <div class="col-xs-12">
                                    <div v-if="contact.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="contact.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in contact.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ contact.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button class="comment-submit text-white"
                                            :disabled="contact.submitting === true">
                                        <span
                                            v-if="contact.submitting === false">{{ $t('editShort.form.label_next') }}</span>
                                        <i class="fa fa-spinner fa-spin" v-if="contact.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading panel-heading--disabled" role="tab" id="playersHeading">
                    <h2 class="panel-title">
                        <a class="collapsed" role="button" href="#playersSection" aria-expanded="false"
                           aria-controls="playersSection">
                            {{ $t('editShort.form.title_player_information') }}
                        </a>
                    </h2>
                </div>
                <div id="playersSection" class="panel-collapse collapse" role="tabpanel" aria-labelledby="playersHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('players')"
                              data-vv-scope="players">
                            <div class="row flex flex-wrap"
                                 v-for="(player, index) in playerInformation.players" :key="index">
                                <div class="col-xs-12 mt-4 mb-10" v-if="index !== 0">
                                    <hr>
                                    <button class="close mr-6" v-on:click="removePlayer(index)"
                                            type="button"
                                            :aria-label="$t('editShort.form.label_remove_player')">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </div>

                                <div class="col-xs-12 col-md-4 item">
                                    <label :for="'playerFirstName' + index">{{ $t('editShort.form.label_first_name')
                                        }}</label>
                                    <input type="text" :id="'playerFirstName' + index" :name="'firstName' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].firstName" autocomplete="fname"
                                           :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                                    <div class="text-red">
                                        {{ getErrorMessageFor('firstName' + index) }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-4 item">
                                    <label :for="'playerMiddleName' + index">{{ $t('editShort.form.label_middle_name')
                                        }}</label>
                                    <input type="text" :id="'playerMiddleName' + index"
                                           :name="'middleName' + index"
                                           key="middleName"
                                           v-model="playerInformation.players[index].middleName" autocomplete="mname">
                                </div>
                                <div class="col-xs-12 col-md-4 item">
                                    <label :for="'playerLastName' + index">{{ $t('editShort.form.label_last_name')
                                        }}</label>
                                    <input type="text" :id="'playerLastName' + index" :name="'lastName' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].lastName" autocomplete="lname"
                                           :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                                    <div class="text-red">
                                        {{ getErrorMessageFor('lastName' + index) }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-3 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_gender') }}</legend>
                                        <label class="item float-left mr-8">
                                            <input type="radio" :name="'gender' + index"
                                                   v-model="playerInformation.players[index].gender"
                                                   value="M" v-validate="'required'"
                                                   :data-vv-as="String($t('editShort.form.label_gender')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_male') }}</span>
                                        </label>
                                        <label class="item float-left">
                                            <input type="radio" :name="'gender' + index"
                                                   v-model="playerInformation.players[index].gender"
                                                   value="F">
                                            <span class="name">{{ $t('editShort.form.label_female') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('gender' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 col-md-3 item">
                                    <label :for="'playerDateOfBirth' + index">
                                        {{ $t('editShort.form.label_dateOfBirth') }}</label>
                                    <input type="text" :id="'playerDateOfBirth' + index" :name="'dateOfBirth' + index"
                                           autocomplete="anystringbutthatstring"
                                           class="datepicker" v-validate="'required'"
                                           data-vv-validate-on="change|select|blur"
                                           v-model="playerInformation.players[index].dateOfBirth"
                                           :data-vv-as="String($t('editShort.form.label_dateOfBirth')).toLowerCase()">
                                    <div class="text-red">
                                        {{ getErrorMessageFor('dateOfBirth' + index) }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-6 col-md-6">
                                    <div class="row">
                                        <div class="col-xs-12 col-sm-6 item">
                                            <label :for="'playerHeight' + index">{{ $t('editShort.form.label_height')
                                                }}</label>
                                            <input type="text" :id="'playerHeight' + index" :name="'height' + index"
                                                   v-validate="'required'"
                                                   v-model="playerInformation.players[index].height"
                                                   :data-vv-as="String($t('editShort.form.label_height')).toLowerCase()">
                                            <div class="text-red">
                                                {{ getErrorMessageFor('height' + index) }}
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-6 item">
                                            <label :for="'playerWeight' + index">{{ $t('editShort.form.label_weight')
                                                }}</label>
                                            <input type="text" :id="'playerWeight' + index" :name="'weight' + index"
                                                   v-validate="'required'"
                                                   v-model="playerInformation.players[index].weight"
                                                   :data-vv-as="String($t('editShort.form.label_weight')).toLowerCase()">
                                            <div class="text-red">
                                                {{ getErrorMessageFor('weight' + index) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 item">
                                    <label :for="'playerPosition' + index">
                                        {{ $t('editShort.form.label_position') }}</label>
                                    <input type="text" :id="'playerPosition' + index" :name="'position' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].position"
                                           :data-vv-as="String($t('editShort.form.label_position')).toLowerCase()">
                                    <div class="text-red">
                                        {{ getErrorMessageFor('position' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_shootLeftRight') }}</legend>
                                        <label class="item float-left mr-8">
                                            <input type="radio" :name="'shootLeftRight' + index" value="L"
                                                   v-model="playerInformation.players[index].shootLeftRight"
                                                   v-validate="'required'"
                                                   :data-vv-as="String($t('editShort.form.label_shootLeftRight')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_left') }}</span>
                                        </label>
                                        <label class="item float-left">
                                            <input type="radio" :name="'shootLeftRight' + index" value="R"
                                                   v-model="playerInformation.players[index].shootLeftRight">
                                            <span class="name">{{ $t('editShort.form.label_right') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('shootLeftRight' + index) }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-6 item">
                                    <label :for="'hockeyExperience' + index">
                                        {{ $t('editShort.form.label_hockeyExperience') }}</label>
                                    <!--
                                    <small class="block -mt-3">{{ $t('editShort.form.label_checkApplicableBoxes') }}
                                    </small>
                                    -->

                                    <select :id="'hockeyExperience' + index" :name="'hockeyExperience' + index"
                                            v-model="playerInformation.players[index].hockeyExperience"
                                            v-validate="'required'"
                                            :data-vv-as="String($t('editShort.form.label_hockeyExperience')).toLowerCase()">
                                        <option v-for="experienceLevel in $root.hockeyExperience"
                                                :value="experienceLevel.articleIndex">
                                            {{ experienceLevel[$validator.locale].mainTitle }}
                                        </option>
                                    </select>

                                    <div class="text-red">
                                        {{ getErrorMessageFor('hockeyExperience' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-sm-6 item">
                                    <label :for="'playerYearsPlayed' + index">
                                        {{ $t('editShort.form.label_yearsPlayed') }}</label>
                                    <input type="number" :id="'playerYearsPlayed' + index" :name="'yearsPlayed' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].yearsPlayed"
                                           :data-vv-as="String($t('editShort.form.label_yearsPlayed')).toLowerCase()">
                                    <div class="text-red">
                                        {{ getErrorMessageFor('yearsPlayed' + index) }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-6 item">
                                    <label :for="'playerHockeyCamps' + index">
                                        {{ $t('editShort.form.label_hockeyCamps') }}</label>
                                    <input type="text" :id="'playerHockeyCamps' + index" :name="'hockeyCamps' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].hockeyCamps"
                                           :data-vv-as="String($t('editShort.form.label_hockeyCamps')).toLowerCase()">
                                    <div class="text-red">
                                        {{ getErrorMessageFor('hockeyCamps' + index) }}
                                    </div>
                                </div>

                                <div class="col-xs-12 col-md-6 item">
                                    <label :for="'playerOtherExperience' + index">
                                        {{ $t('editShort.form.label_otherExperience') }}</label>
                                    <input type="text" :id="'playerOtherExperience' + index"
                                           :name="'otherExperience' + index"
                                           v-model="playerInformation.players[index].otherExperience"
                                           :data-vv-as="String($t('editShort.form.label_otherExperience')).toLowerCase()">
                                    <div class="text-red">
                                        {{ getErrorMessageFor('otherExperience' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 mt-16 mb-10 font-bold">
                                    <p>{{ $t('editShort.form.pleaseAnswerTheFollowing') }}</p>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_haveT1dFriend') }}</legend>
                                        <label class="item float-left mr-8">
                                            <input type="radio" :name="'haveT1dFriend' + index" value="Y"
                                                   v-validate="'required'"
                                                   v-model="playerInformation.players[index].hasT1dFriend"
                                                   :data-vv-as="String($t('editShort.form.label_haveT1dFriend')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_yes') }}</span>
                                        </label>
                                        <label class="item float-left">
                                            <input type="radio" :name="'haveT1dFriend' + index" value="N"
                                                   v-model="playerInformation.players[index].hasT1dFriend">
                                            <span class="name">{{ $t('editShort.form.label_no') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('haveT1dFriend' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_doFriendsHelp') }}</legend>
                                        <label class="item float-left mr-8">
                                            <input type="radio" :name="'doFriendsHelp' + index" value="Y"
                                                   v-validate="'required'"
                                                   v-model="playerInformation.players[index].doFriendsHelp"
                                                   :data-vv-as="String($t('editShort.form.label_doFriendsHelp')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_yes') }}</span>
                                        </label>
                                        <label class="item float-left">
                                            <input type="radio" :name="'doFriendsHelp' + index" value="N"
                                                   v-model="playerInformation.players[index].doFriendsHelp">
                                            <span class="name">{{ $t('editShort.form.label_no') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('doFriendsHelp' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_otherDiabetesCamps') }}</legend>
                                        <label class="item float-left mr-8">
                                            <input type="radio" :name="'otherDiabetesCamps' + index" value="Y"
                                                   v-validate="'required'"
                                                   v-model="playerInformation.players[index].otherDiabetesCamps"
                                                   :data-vv-as="String($t('editShort.form.label_otherDiabetesCamps')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_yes') }}</span>
                                        </label>
                                        <label class="item float-left">
                                            <input type="radio" :name="'otherDiabetesCamps' + index" value="N"
                                                   v-model="playerInformation.players[index].otherDiabetesCamps">
                                            <span class="name">{{ $t('editShort.form.label_no') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('otherDiabetesCamps' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_t1dRoleModel') }}</legend>
                                        <label class="item float-left mr-8">
                                            <input type="radio" :name="'t1dRoleModel' + index" value="Y"
                                                   v-validate="'required'"
                                                   v-model="playerInformation.players[index].hasT1dRoleModel"
                                                   :data-vv-as="String($t('editShort.form.label_t1dRoleModel')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_yes') }}</span>
                                        </label>
                                        <label class="item float-left">
                                            <input type="radio" :name="'t1dRoleModel' + index" value="N"
                                                   v-model="playerInformation.players[index].hasT1dRoleModel">
                                            <span class="name">{{ $t('editShort.form.label_no') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('t1dRoleModel' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6 item">
                                    <fieldset>
                                        <legend>{{ $t('editShort.form.label_knowAnyoneElse') }}</legend>
                                        <label class="item float-left mr-8">
                                            <input type="radio" :name="'knowAnyoneElse' + index" value="Y"
                                                   v-validate="'required'"
                                                   v-model="playerInformation.players[index].knowsAnyoneElse"
                                                   :data-vv-as="String($t('editShort.form.label_knowAnyoneElse')).toLowerCase()">
                                            <span class="name">{{ $t('editShort.form.label_yes') }}</span>
                                        </label>
                                        <label class="item float-left">
                                            <input type="radio" :name="'knowAnyoneElse' + index" value="N"
                                                   v-model="playerInformation.players[index].knowsAnyoneElse">
                                            <span class="name">{{ $t('editShort.form.label_no') }}</span>
                                        </label>
                                    </fieldset>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('knowAnyoneElse' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label :for="'playerHobbies' + index">
                                        {{ $t('editShort.form.label_hobbies') }}</label>
                                    <input type="text" :id="'playerHobbies' + index" :name="'hobbies' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].hobbies"
                                           :data-vv-as="String($t('editShort.form.label_hobbies')).toLowerCase()"/>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('hobbies' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label :for="'playerFavoriteFood' + index">
                                        {{ $t('editShort.form.label_favoriteFood') }}</label>
                                    <input type="text" :id="'playerFavoriteFood' + index" :name="'favoriteFood' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].favoriteFood"
                                           :data-vv-as="String($t('editShort.form.label_favoriteFood')).toLowerCase()"/>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('favoriteFood' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label :for="'playerFavoriteHockeyTeam' + index">
                                        {{ $t('editShort.form.label_favoriteHockeyTeam') }}</label>
                                    <input type="text" :id="'playerFavoriteHockeyTeam' + index"
                                           :name="'favoriteHockeyTeam' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].favoriteHockeyTeam"
                                           :data-vv-as="String($t('editShort.form.label_favoriteHockeyTeam')).toLowerCase()"/>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('favoriteHockeyTeam' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label :for="'playerFavoriteHockeyPlayer' + index">
                                        {{ $t('editShort.form.label_favoriteHockeyPlayer') }}</label>
                                    <input type="text" :id="'playerFavoriteHockeyPlayer' + index"
                                           :name="'favoriteHockeyPlayer' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].favoriteHockeyPlayer"
                                           :data-vv-as="String($t('editShort.form.label_favoriteHockeyPlayer')).toLowerCase()"/>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('favoriteHockeyPlayer' + index) }}
                                    </div>
                                </div>
                                <div class="col-xs-12 item">
                                    <label :for="'playerWouldLikeToLearn' + index">
                                        {{ $t('editShort.form.label_wouldLikeToLearn') }}</label>
                                    <input type="text" :id="'playerWouldLikeToLearn' + index"
                                           :name="'wouldLikeToLearn' + index"
                                           v-validate="'required'"
                                           v-model="playerInformation.players[index].wouldLikeToLearn"
                                           :data-vv-as="String($t('editShort.form.label_wouldLikeToLearn')).toLowerCase()"/>
                                    <div class="text-red">
                                        {{ getErrorMessageFor('wouldLikeToLearn' + index) }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xs-12">
                                    <div v-if="playerInformation.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="playerInformation.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in playerInformation.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ playerInformation.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button class="comment-submit text-white"
                                            type="button" v-on:click="addPlayer"
                                            :disabled="playerInformation.submitting === true">
                                        <span
                                            v-if="playerInformation.submitting === false">{{ $t('editShort.form.label_add_player') }}</span>
                                        <i class="fa fa-spinner fa-spin"
                                           v-if="playerInformation.submitting === true"></i>
                                    </button>
                                    <button class="comment-submit text-white"
                                            :disabled="playerInformation.submitting === true">
                                        <span
                                            v-if="playerInformation.submitting === false">{{ $t('editShort.form.label_next') }}</span>
                                        <i class="fa fa-spinner fa-spin"
                                           v-if="playerInformation.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading panel-heading--disabled" role="tab" id="parentsHeading">
                    <h2 class="panel-title">
                        <a class="collapsed" role="button" href="#parentsSection" aria-expanded="false"
                           aria-controls="parentsSection">
                            {{ $t('editShort.form.title_parent_information') }}
                        </a>
                    </h2>
                </div>
                <div id="parentsSection" class="panel-collapse collapse" role="tabpanel"
                     aria-labelledby="parentsHeading">
                    <div class="panel-body">
                        <form action="#" method="post" @submit.prevent="submit('parents')"
                              data-vv-scope="parents">

                            <div v-for="(parent, index) in parentInformation.parents" :key="index">
                                <div class="row mt-4 mb-10" v-if="index !== 0">
                                    <div class="col-xs-12">
                                        <hr>
                                        <button class="close mr-6" v-on:click="removeParent(index)"
                                                :aria-label="$t('editShort.form.label_remove_parent')" type="button">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="row flex flex-wrap items-center">
                                    <div class="col-xs-12 col-md-4 item">
                                        <label :for="'parentRelation' + index" class="font-weight-normal">
                                            {{ $t('editShort.form.label_relation') }}</label>
                                        <select :id="'parentRelation' + index" :name="'relation' + index"
                                                v-model="parentInformation.parents[index].relation"
                                                v-validate="'required'"
                                                :data-vv-as="String($t('editShort.form.label_relation')).toLowerCase()">
                                            <option value="Father">
                                                {{ $t('editShort.form.label_father') }}
                                            </option>
                                            <option value="Mother">
                                                {{ $t('editShort.form.label_mother') }}
                                            </option>
                                            <option value="Guardian">
                                                {{ $t('editShort.form.label_guardian') }}
                                            </option>
                                        </select>
                                        <div class="text-red">
                                            {{ getErrorMessageFor('relation' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-8 text-right">
                                        <button v-on:click="parentCopyAddress(index)" class="comment-submit text-white"
                                                type="button">
                                            {{ $t('editShort.form.label_copy_address_from_contact') }}
                                        </button>
                                    </div>
                                </div>

                                <div class="row flex flex-wrap">

                                    <div class="col-xs-12 col-md-4 item">
                                        <label :for="'parentFirstName' + index">{{ $t('editShort.form.label_first_name')
                                            }}</label>
                                        <input type="text" :id="'parentFirstName' + index"
                                               :name="'firstName' + index"
                                               v-validate="'required'"
                                               v-model="parentInformation.parents[index].firstName" autocomplete="fname"
                                               :data-vv-as="String($t('editShort.form.label_first_name')).toLowerCase()">
                                        <div class="text-red">
                                            {{ getErrorMessageFor('firstName' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-4 item">
                                        <label :for="'parentLastName' + index">{{ $t('editShort.form.label_last_name')
                                            }}</label>
                                        <input type="text" :id="'parentLastName' + index"
                                               :name="'lastName' + index"
                                               v-validate="'required'"
                                               v-model="parentInformation.parents[index].lastName" autocomplete="lname"
                                               :data-vv-as="String($t('editShort.form.label_last_name')).toLowerCase()">
                                        <div class="text-red">
                                            {{ getErrorMessageFor('lastName' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-4 item">
                                        <label :for="'parentEmail' + index">
                                            {{ $t('editShort.form.label_email') }}</label>
                                        <input type="email" :id="'parentEmail' + index" :name="'email' + index"
                                               v-validate="'required'"
                                               v-model="parentInformation.parents[index].email" autocomplete="email"
                                               :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                                        <div class="text-red">
                                            {{ getErrorMessageFor('email' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 item">
                                        <label :for="'parentAddress' + index">
                                            {{ $t('editShort.form.label_address') }}
                                            ({{ $t('editShort.form.label_if_different_from_player') }})
                                        </label>
                                        <input type="text" :id="'parentAddress' + index" :name="'address' + index"
                                               v-validate="'required'"
                                               v-model="parentInformation.parents[index].address"
                                               autocomplete="street-address"
                                               :data-vv-as="String($t('editShort.form.label_address')).toLowerCase()">
                                        <div class="text-red">
                                            {{ getErrorMessageFor('address' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 item">
                                        <label :for="'parentCountry' + index">
                                            {{ $t('editShort.form.label_country') }}</label>
                                        <select :id="'parentCountry' + index" :name="'country' + index"
                                                autocomplete="country"
                                                v-on:change="parentCountryOnChange(index)"
                                                v-model="parentInformation.parents[index].country"
                                                v-validate="'required'"
                                                :data-vv-as="String($t('editShort.form.label_country')).toLowerCase()">
                                            <option v-for="country in countries" v-bind:value="country.ISOCode">
                                                {{ country[$validator.locale].mainTitle }}
                                            </option>
                                        </select>
                                        <div class="text-red">
                                            {{ getErrorMessageFor('country' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 item">
                                        <label :for="'parentCity' + index">{{ $t('editShort.form.label_city') }}</label>
                                        <input type="text" :id="'parentCity' + index" :name="'city' + index"
                                               v-validate="'required'"
                                               v-model="parentInformation.parents[index].city"
                                               autocomplete="address-level2"
                                               :data-vv-as="String($t('editShort.form.label_city')).toLowerCase()">
                                        <div class="text-red">
                                            {{ getErrorMessageFor('city' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 item">
                                        <label :for="'parentRegion' + index">
                                            {{ $t('editShort.form.label_state_province') }}</label>
                                        <select :id="'parentRegion' + index" :name="'region' + index"
                                                v-model="parentInformation.parents[index].region"
                                                autocomplete="address-level1"
                                                v-validate="'required'"
                                                :data-vv-as="String($t('editShort.form.label_state_province')).toLowerCase()">
                                            <option v-for="region in parentInformation.parents[index].regions"
                                                    :value="region.ISOCode">
                                                {{ region[$validator.locale].mainTitle }}
                                            </option>
                                        </select>
                                        <div class="text-red">
                                            {{ getErrorMessageFor('region' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 item">
                                        <label :for="'parentPostalCode' + index">
                                            {{$t('editShort.form.label_postal_code')}}</label>
                                        <input type="text" :id="'parentPostalCode' + index"
                                               :name="'postalCode' + index"
                                               v-validate="'required'"
                                               v-model="parentInformation.parents[index].postalCode"
                                               autocomplete="postal-code"
                                               :data-vv-as="String($t('editShort.form.label_postal_code')).toLowerCase()">
                                        <div class="text-red">
                                            {{ getErrorMessageFor('postalCode' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 item">
                                        <label :for="'parentCellPhone' + index">
                                            {{$t('editShort.form.label_business_cell_phone')}}</label>
                                        <input type="text" :id="'parentCellPhone' + index"
                                               :name="'cellPhone' + index"
                                               v-validate="'required'"
                                               v-model="parentInformation.parents[index].cellPhone" autocomplete="tel"
                                               :data-vv-as="String($t('editShort.form.label_business_cell_phone')).toLowerCase()">
                                        <div class="text-red">
                                            {{ getErrorMessageFor('cellPhone' + index) }}
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 item">
                                        <label :for="'parentHomePhone' + index">{{ $t('editShort.form.label_home_phone')
                                            }}</label>
                                        <input type="text" :id="'parentHomePhone' + index"
                                               :name="'homePhone' + index"
                                               v-validate="'required'"
                                               v-model="parentInformation.parents[index].homePhone" autocomplete="tel"
                                               :data-vv-as="String($t('editShort.form.label_home_phone')).toLowerCase()">
                                        <div class="text-red">
                                            {{ getErrorMessageFor('homePhone' + index) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row flex flex-wrap">
                                <input type="hidden" name="timestamp" v-model="$root.timestamp">
                                <div class="col-xs-12">
                                    <div v-if="parentInformation.errorMessages.length > 0"
                                         class="alert alert-danger animated fadeInDown">
                                        <ul v-if="parentInformation.errorMessages.length > 1" class="mt-0">
                                            <li class="w-100 text-left font-weight-normal"
                                                v-for="message in parentInformation.errorMessages">
                                                {{ message }}
                                            </li>
                                        </ul>
                                        <span v-else>{{ parentInformation.errorMessages[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xs-12 text-right">
                                    <button v-on:click="addParent()" class="comment-submit text-white"
                                            type="button">
                                        {{ $t('editShort.form.label_add_parent') }}
                                    </button>
                                    <button class="comment-submit text-white"
                                            :disabled="parentInformation.submitting === true">
                                        <span
                                            v-if="parentInformation.submitting === false">{{ $t('editShort.form.label_send') }}</span>
                                        <i class="fa fa-spinner fa-spin"
                                           v-if="parentInformation.submitting === true"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="successMessage.length > 0" class="alert alert-success animated fadeInDown">
            <span>{{ successMessage }}</span>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import 'pikaday/css/pikaday.css';

    import Pikaday from 'pikaday';

    let bsn = require('bootstrap.native');
    let contactCollapse, playerCollapse, parentCollapse;
    let now = new Date();

    export default {
        name: 'eventRegistration'
        , props: ['eventRegistrationTypeGuid', 'languageIndex']
        , data: function () {
            let data = {
                contact: {
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    address: '',
                    city: '',
                    country: '',
                    region: '',
                    postalCode: '',
                    submitting: false,
                    submitted: false,
                    errorMessages: [],
                    regions: []
                }
                , pikadayOptions: {}

                , playerInformation: {
                    players: []
                    , playersMetaData: []
                    , submitting: false
                    , submitted: false
                    , errorMessages: []
                }
                , parentInformation: {
                    parents: []
                    , submitting: false
                    , submitted: false
                    , errorMessages: []
                }
                , countries: {}
                , regions: {}
                , successMessage: ''
            };

            if (this.languageIndex === 'fr') {
                data.pikadayOptions.i18n = {
                    previousMonth: 'Le mois précédent',
                    nextMonth: 'Le mois prochain',
                    months: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
                    weekdays: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
                    weekdaysShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam']
                };
                data.pikadayOptions.firstDay = 1;
            }
            return data;
        }
        , created() {
            axios.get('/api/v1/geo-countries/')
                .then(response => {
                    this.countries = Object.values(response.data.data['geo-country']);
                    return axios.get('/api/v1/geo-country-regions/')
                })
                .then(response => {
                    this.regions = Object.values(response.data.data['geo-country-region']);
                    return Promise.resolve();
                })
                .then(() => {
                    return axios.post(
                        '/process/eventRegistration.php'
                        , {action: 'retrieveFormData'}
                        , {headers: {'Content-Type': 'application/json'}}
                    );
                })
                .then(response => {

                    //Load form where user left off
                    if (response.data.data !== null) {
                        if ('contact' in response.data.data) {
                            this.contact = Object.assign(this.contact, response.data.data.contact);
                            this.countryOnChange('contact');
                            this.contact.region = response.data.data.contact.region;
                        }
                        if ('playerInformation' in response.data.data) {
                            this.playerInformation.players = Object.values(response.data.data.playerInformation.players);
                            this.playerInformation.players.forEach((player, index) => {
                                this.startPlayerDatePicker(index);
                            });
                        }
                        if ('parentInformation' in response.data.data) {
                            this.parentInformation.parents = Object.values(response.data.data.parentInformation.parents);
                            this.parentInformation.parents.forEach((parent, index) => {
                                this.parentCountryOnChange(index);
                                parent.region = response.data.data.parentInformation.parents[index].region;
                            });
                        }
                    }

                });
        }
        , mounted() {
            this.$nextTick(() => {
                contactCollapse = new bsn.Collapse(document.querySelector('#contactHeading a'), {
                    parent: '#accordion'
                });
                playerCollapse = new bsn.Collapse(document.querySelector('#playersHeading a'), {
                    parent: '#accordion'
                });
                parentCollapse = new bsn.Collapse(document.querySelector('#parentsHeading a'), {
                    parent: '#accordion'
                });

                document.getElementById('contactSection').addEventListener('shown.bs.collapse', () => {
                    this.scrollToSection('contact');
                });
                document.getElementById('playersSection').addEventListener('show.bs.collapse', () => {
                    if (this.playerInformation.players.length === 0) {
                        this.addPlayer();
                    }
                });
                document.getElementById('playersSection').addEventListener('shown.bs.collapse', () => {
                    this.scrollToSection('players');
                });
                document.getElementById('parentsSection').addEventListener('show.bs.collapse', () => {
                    if (this.parentInformation.parents.length === 0) {
                        this.addParent();
                    }
                });
                document.getElementById('parentsSection').addEventListener('shown.bs.collapse', () => {
                    this.scrollToSection('parents');
                });
            });
        }
        , methods: {
            countryOnChange(section) {
                let selectedCountry = this.countries.find(country => {
                    return country.ISOCode === this[section].country;
                });
                this[section].regions = this.regions.filter(region => {
                    return selectedCountry.ID === region.geographyCountryID;
                });
            }
            , validatePlayerDateOfBirth(index) {
                this.$validator.validate(
                    'players.dateOfBirth' + index
                    , this.playerInformation.players[index].dateOfBirth
                )
                    .then(response => {
                        console.log(response);
                    });
            }
            , addPlayer() {
                let newPlayer = {
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    gender: '',
                    height: '',
                    weight: '',
                    dateOfBirth: '',
                    position: '',
                    shootLeftRight: '',
                    hockeyExperience: '', //novice, peewee, etc.
                    hockeyCamps: '',
                    yearsPlayed: '',
                    otherExperience: '',

                    hasT1dFriend: '',
                    doFriendsHelp: '',
                    otherDiabetesCamps: '',
                    hasT1dRoleModel: '',
                    knowsAnyoneElse: '',
                    hobbies: '',
                    favoriteFood: '',
                    favoriteHockeyTeam: '',
                    favoriteHockeyPlayer: '',
                    wouldLikeToLearn: '',

                };
                this.playerInformation.players.push(newPlayer);

                this.startPlayerDatePicker(this.playerInformation.players.length - 1);

            }
            , startPlayerDatePicker(index) {
                let options = {}, playerMetaData, currentDateOfBirth;
                const player = this.playerInformation.players[index];
                const newPlayerMetaData = {
                    datePicker: ''
                };
                this.playerInformation.playersMetaData.push(newPlayerMetaData);
                playerMetaData = this.playerInformation.playersMetaData[index];
                this.$nextTick(() => {
                    options = Object.assign({
                            field: document.getElementById('playerDateOfBirth' + index)
                            //Why an extra 2 years?
                            //- 1 extra year for someone who super-loves hockey
                            //- 1 extra year for a cheap bug fix
                            , yearRange: [now.getFullYear() - 19, now.getFullYear() - 6]
                            , onSelect: function (date) {
                                player.dateOfBirth = '' + date.getFullYear() + '-';
                                player.dateOfBirth += String(date.getMonth()).padStart(2, '0') + '-';
                                player.dateOfBirth += String(date.getDate()).padStart(2, '0');
                            }
                        }
                        , this.pikadayOptions
                    );
                    playerMetaData.datePicker = new Pikaday(options);
                    if (player.dateOfBirth.length > 0) {
                        playerMetaData.datePicker.setDate(player.dateOfBirth);
                        currentDateOfBirth = playerMetaData.datePicker.getDate();

                        player.dateOfBirth = '' + currentDateOfBirth.getFullYear() + '-';
                        player.dateOfBirth += String(currentDateOfBirth.getMonth()).padStart(2, '0') + '-';
                        player.dateOfBirth += String(currentDateOfBirth.getDate()).padStart(2, '0');
                    }
                });
            }
            , removePlayer(index) {
                this.playerInformation.players.splice(index, 1);
                this.playerInformation.playersMetaData.splice(index, 1);
            }
            , addParent() {
                let newParent = {
                    relation: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    address: '',
                    city: '',
                    country: '',
                    region: '',
                    postalCode: '',
                    cellPhone: '',
                    homePhone: '',
                    regions: ''
                };
                this.parentInformation.parents.push(newParent);
            }
            , removeParent(index) {
                this.parentInformation.parents.splice(index, 1);
            }
            , parentCountryOnChange(index) {
                let selectedCountry = this.countries.find(country => {
                    return country.ISOCode === this.parentInformation.parents[index].country;
                });
                this.parentInformation.parents[index].regions = this.regions.filter(region => {
                    return selectedCountry.ID === region.geographyCountryID;
                });
            }
            , parentCopyAddress(index) {
                this.parentInformation.parents[index].email = this.contact.email;
                this.parentInformation.parents[index].address = this.contact.address;
                this.parentInformation.parents[index].city = this.contact.city;
                this.parentInformation.parents[index].country = this.contact.country;
                this.parentCountryOnChange(index);
                this.parentInformation.parents[index].region = this.contact.region;
                this.parentInformation.parents[index].postalCode = this.contact.postalCode;
                this.parentInformation.parents[index].homePhone = this.contact.phone
            }
            //In v-for, error messages get a bit dicey. Use this instead of the usual method.
            , getErrorMessageFor(fieldName) {
                let errorItem = this.errors.items.find((item) => {
                    return item.field === fieldName
                });
                let errorMessage = typeof errorItem !== 'undefined' ? errorItem.msg : '';
                return errorMessage;
            }
            , submit(scope) {
                this.$validator.validateAll(scope).then(result => {
                    if (result) {
                        switch (scope) {
                            case 'contact':
                                this.submitContact();
                                break;
                            case 'players':
                                this.submitPlayerInformation();
                                break;
                            case 'parents':
                                this.submitParents();
                                break;
                        }
                    } else {
                        this.scrollToError(scope);
                    }
                });
            }
            , submitContact() {
                let response;
                this.contact.submitting = true;
                response = axios.post('/process/eventRegistration.php', {
                        action: 'registerContact'
                        , eventRegistrationTypeGUID: this.eventRegistrationTypeGuid
                        , languageIndex: this.languageIndex
                        , timestamp: this.$root.timestamp
                        , firstName: this.contact.firstName
                        , middleName: this.contact.middleName
                        , lastName: this.contact.lastName
                        , phone: this.contact.phone
                        , email: this.contact.email
                        , address: this.contact.address
                        , city: this.contact.city
                        , country: this.contact.country
                        , region: this.contact.region
                        , postalCode: this.contact.postalCode
                    },
                    {
                        headers: {
                            'Content-Type':
                                'application/json'
                        }
                    }
                )
                    .then(response => {
                        this.contact.submitting = false;
                        if (response.data.success === true) {
                            this.contact.errorMessages = [];
                            contactCollapse.hide();
                            document.getElementById('contactSection').classList.remove('in');
                            document.getElementById('playersHeading').classList.remove('panel-heading--disabled');
                            playerCollapse.show();
                        } else {
                            this.contact.errorMessages = response.data.messages;
                        }
                    });
            }
            , submitPlayerInformation() {
                let response;
                this.playerInformation.submitting = true;
                response = axios.post('/process/eventRegistration.php', {
                        action: 'registerPlayerInformation'
                        , eventRegistrationTypeGUID: this.eventRegistrationTypeGuid
                        , languageIndex: this.languageIndex
                        , timestamp: this.$root.timestamp
                        , players: this.playerInformation.players
                    },
                    {
                        headers: {
                            'Content-Type':
                                'application/json'
                        }
                    }
                )
                    .then(response => {
                        this.playerInformation.submitting = false;
                        if (response.data.success === true) {
                            this.playerInformation.errorMessages = [];
                            playerCollapse.hide();
                            document.getElementById('playersSection').classList.remove('in');
                            document.getElementById('parentsHeading').classList.remove('panel-heading--disabled');
                            parentCollapse.show();
                        } else {
                            this.playerInformation.errorMessages = response.data.messages;
                        }
                    });
            }
            , submitParents() {
                let response;
                this.parentInformation.submitting = true;
                response = axios.post('/process/eventRegistration.php', {
                        action: 'registerParents'
                        , languageIndex: this.languageIndex
                        , timestamp: this.$root.timestamp
                        , eventRegistrationTypeGUID: this.eventRegistrationTypeGuid
                        , parents: this.parentInformation.parents
                    }
                    , {headers: {'Content-Type': 'application/json'}}
                )
                    .then(
                        response => {
                            if (response.data.success === true) {
                                parentCollapse.hide();
                                document.getElementById('parentsSection').classList.remove('in');
                                this.parentInformation.errorMessages = [];
                                this.successMessage = this.$t('editShort.form.success_message');
                                this.parentInformation.submitted = true;
                            } else if (response.data.messages.length > 0) {
                                this.parentInformation.errorMessages = response.data.messages;
                            } else {
                                this.parentInformation.errorMessages.push(this.$t('editShort.form.error_message'));
                            }
                        }
                        , error => {
                            console.log(error);
                            this.parentInformation.errorMessages.push(this.$t('editShort.form.error_message'));

                        }
                    )
                    .finally(() => {
                        this.parentInformation.submitting = false;
                    });
            }
        }
    }
</script>

<style scoped>
    .panel {
        background-color: transparent;
    }

    .item input, .item select, .item textarea {
        margin-top: auto;
    }

    .hockey-experience .filter-check + label {
        width: 100%;
        padding-left: 2.5rem;
        flex-basis: 0;
        flex-grow: 1;
        min-width: 33.3333%;
        max-width: 100%;
    }

</style>

