import axios from 'axios';

function loadMoreArticles(lastPostID, categoryID, languageIndex, baseDomain, baseUrl) {
	/*
	//This works, but there is no backend pagination at the moment
	return axios.get('/api/v1/blog/articles/');
	*/

	/*
	//This doesn't work
	return axios.post('/api/v1/blog/articles/', {
		action: 'load',
		id: lastPostID,
		categoryId: categoryID,
		languageIndex: languageIndex,
		baseDomain: baseDomain,
		baseUrl: baseUrl
	},
	{
		headers: {
			'Content-Type': 'application/json'
		}
	});
	*/

	return axios.post('/process/blog.php', {
		action: 'load',
		id: lastPostID,
		categoryId: categoryID,
		languageIndex: languageIndex,
		baseDomain: baseDomain,
		baseUrl: baseUrl
	},
	{
		headers: {
			'Content-Type': 'application/json'
		}
	});
}

export default {
	loadMoreArticles: loadMoreArticles
};
