<template>
    <form id="contactForm" action="#" method="post" @submit.prevent="validateBeforeSubmit">
        <div class="row">
            <div class="col-md-6">
                <div class="item">
                    <label>
                        <span>{{ $t('editShort.form.label_name') }} <i>*</i></span>
                        <input type="text" name="name" v-validate="'required'" v-model="name" autocomplete="name"
                               :data-vv-as="String($t('editShort.form.label_name')).toLowerCase()">
                        <div class="text-red">
                            {{ errors.first('name') }}
                        </div>
                    </label>
                </div>
            </div>
            <div class="col-md-6">
                <div class="item">
                    <label>
                        <span>{{ $t('editShort.form.label_email') }} <i>*</i></span>
                        <input type="email" name="email" v-validate="'required'" v-model="email" autocomplete="email"
                               :data-vv-as="String($t('editShort.form.label_email')).toLowerCase()">
                        <div class="text-red">
                            {{ errors.first('email') }}
                        </div>
                    </label>
                </div>
            </div>
            <div class="col-md-12">
                <div class="item">
                    <label>
                        <span>{{ $t('editShort.form.label_subject') }}</span>
                        <input type="text" name="subject" v-model="subject"
                               :data-vv-as="String($t('editShort.form.label_subject')).toLowerCase()">
                    </label>
                </div>
            </div>
            <div class="col-md-12">
                <div class="item">
                    <label>
                        <span>{{ $t('editShort.form.label_message') }}<i>*</i></span>
                        <textarea v-validate="'required'" v-model="message" name="message"
                                  :data-vv-as="String($t('editShort.form.label_message')).toLowerCase()"></textarea>
                        <div class="text-red">
                            {{ errors.first('message') }}
                        </div>
                    </label>
                </div>
            </div>
            <input type="hidden" name="timestamp" v-model="$root.timestamp">
            <div class="col-xs-12">
                <div v-if="successMessage.length > 0" class="alert alert-success animated fadeInDown">
                    <span>
                        {{ successMessage }}
                    </span>
                </div>
                <div v-if="errorMessages.length > 0" class="alert alert-danger animated fadeInDown">
                    <ul v-if="errorMessages.length > 1" class="mt-0">
                        <li class="w-100 text-left font-weight-normal" v-for="message in errorMessages">
                            {{ message }}
                        </li>
                    </ul>
                    <span v-else>
                        {{ errorMessages[0] }}
                    </span>
                </div>
            </div>
            <div class="col-md-12 text-right">
                <button class="comment-submit text-white" :disabled="submitting === true"
                        :class="submitted ? 'hidden' : ''">
                    <span v-if="submitting === false">{{ $t('editShort.form.label_send_message') }}</span>
                    <i class="fa fa-spinner fa-spin" v-if="submitting === true"></i>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'contactForm'
        , data: function () {
            return {
                name: '',
                email: '',
                subject: '',
                message: '',
                successMessage: '',
                errorMessages: [],
                submitting: false,
                submitted: false
            }
        }
        , methods: {
            validateBeforeSubmit() {
                this.$validator.validateAll().then(result => {
                    let response;
                    if (result) {
                        this.submitting = true;

                        response = axios.post('/process/contact.php', {
                                action: 'contact',
                                name: this.name,
                                //phone: this.phone,
                                email: this.email,
                                subject: this.subject,
                                message: this.message,
                                timestamp: this.$root.timestamp
                            },
                            {
                                headers: {
                                    'Content-Type':
                                        'application/json'
                                }
                            }
                        )
                            .then(response => {
                                this.submitting = false;
                                if (response.data.success === true) {
                                    this.errorMessages = [];
                                    this.successMessage = this.$t('editShort.form.success_message');
                                    this.submitted = true;
                                } else {
                                    this.errorMessages = response.data.messages;
                                }
                            });
                    }
                });
            }
        }
    }
</script>

